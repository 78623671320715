import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function convertStatus(status) {
  /**
   * verilen status durumuna göre text ve color rengi verilmekte
   */

  const statusMap = {
    PENDING: {
      text: "Askıda Bekliyor",
      color: "bg-yellow-100 text-yellow-800",
    },
    TOAPPROVAL: {
      text: "Sorumlu Onay Bekliyor",
      color: "bg-blue-100 text-blue-800",
    },
    NEEDREVISE: {
      text: "Revize istiyor",
      color: "bg-gray-100 text-gray-800 ",
    },
    INREVISE: {
      text: "Revize edilebilir",
      color: "bg-gray-100 text-gray-800 ",
    },

    SUCCESS: {
      text: "Onaylandı",
      color: "bg-green-100 text-green-800 ",
    },
    REJECTED: {
      text: "Form Reddedildi",
      color: "bg-red-100 text-red-800 ",
    },
  };

  const defaultStatus = {
    text: "bilinmeyen durum",
    color: "bg-gray-100 text-gray-800",
  };

  const { text, color } = statusMap[status] || defaultStatus;

  return { text, color };
}
