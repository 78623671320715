export const apiconfig = () => {
    if (process.env.NODE_ENV === 'production') {
        return {
            url: window.location.origin
        }
    } else {
        return {
            url: process.env.REACT_APP_API_URL
        }
    }
} 