import { Button } from '@/components/ui/button'
import { Input } from "@/components/ui/input"
import { Textarea } from "@/components/ui/textarea"
import { Switch } from '@/components/ui/switch'
import { Checkbox } from "@/components/ui/checkbox"
import { Delete, Move, Plus, Trash2 } from "lucide-react"
import { Label } from "@/components/ui/label"
import { useEffect, useState } from 'react'

const FieldContainer = ({ field, editable, children, handleChange = () => {}, handleDelete = () => {} }) => {
  return (
    <div key={field.id}>
      <div className='flex items-center mb-1 space-x-2'>
        <div className='text-sm text-gray-500'>
          {field.id}.
        </div>
        <div className='py-1 rounded-lg bg-white grid space-y-1 w-full'>
          {field.type == 'form_description_textbox' ? (
            <div className="font-medium my-auto text-sm">
              FORM AÇIKLAMASI
            </div>
          ) : editable ? (
            <Input
              type="text"
              placeholder={field.title || 'Başlık'}
              value={field[field.title_name] || ''}
              onChange={e => handleChange(field.id, e)}
              className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
              name={field.title_name}
              required
            />
          ) : (
            <div className="disabled font-medium my-auto text-sm">
              {field[field.title_name] || ''}
            </div>
          )}
        </div>
        {editable && (
          <>
            <Move className='text-red-600 cursor-not-allowed cursor-pointer mb-1 disabled' size={20} />
            <Trash2 onClick={e => handleDelete(field.id, e)} className='text-red-600 cursor-pointer mb-1' size={20} />
          </>
        )}
      </div>
      {children}
    </div>
  );
};

const TextInput = ({ editable, preview = false, field, handleChange, handleDelete }) => {
  return (
    <FieldContainer
      field={field}
      editable={editable}
      handleChange={handleChange}
      handleDelete={handleDelete}
    >
      {preview ? 
        <Label className="my-auto text-sm font-bold ms-5">{field[field.input_name]}</Label>
      :
        <Input
          type={field.type}
          name={field.input_name}
          placeholder={editable && "Varsayılan yanıt..."}
          value={field[field.input_name]}
          onChange={(e) => handleChange(field.id, e)}
        />
      }
    </FieldContainer>
  )
}

const TextArea = ({ editable, preview = false, readonly=false, field, handleChange, handleDelete }) => {
  return (
    <FieldContainer
      field={field}
      editable={editable}
      handleChange={handleChange}
      handleDelete={handleDelete}
    >
      {preview || readonly ? 
        <Label className="my-auto text-sm font-bold ms-5">{field[field.input_name]}</Label>
      :
        <Textarea
          name={field.input_name}
          placeholder={!editable && field[field.input_name]}
          value={editable && field[field.input_name]}
          onChange={e => handleChange(field.id, e)}
        />
      }
    </FieldContainer>
  );
};

const SwitchInput = ({ editable, preview = false, field, handleChange, handleDelete }) => {
  const [showConditionalInputYes, setShowConditionalInputYes] = useState(!!field.yes_reason_title);
  const [showConditionalInputNo, setShowConditionalInputNo] = useState(!!field.no_reason_title);

  const handleSwitchToggle = (checked) => {
    handleChange(field.id, { target: { name: 'switch_checked', value: checked } });
  };

  const handleAddConditionalInputYes = () => {
    setShowConditionalInputYes(true);
  };

  const handleRemoveConditionalInputYes = () => {
    setShowConditionalInputYes(false);
    handleChange(field.id, { target: { name: 'yes_reason_title', value: '' } });
    handleChange(field.id, { target: { name: 'yes_reason_text', value: '' } });
  };

  const handleAddConditionalInputNo = () => {
    setShowConditionalInputNo(true);
  };

  const handleRemoveConditionalInputNo = () => {
    setShowConditionalInputNo(false);
    handleChange(field.id, { target: { name: 'no_reason_title', value: '' } });
    handleChange(field.id, { target: { name: 'no_reason_text', value: '' } });
  };

  return (
    <FieldContainer
      field={field}
      editable={editable}
      handleChange={handleChange}
      handleDelete={handleDelete}
    >
      <div className="flex flex-col w-full space-y-2">
        <div className='flex items-center space-x-2'>
          {editable ? (
            <div className='grid grid-cols-[1fr_auto_1fr] gap-4 gap-y-2 w-full'>
              <Input
                type="text"
                defaultValue={field.yes_text}
                onChange={e => handleChange(field.id, e)}
                className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                name={field.yes_name}
                required
              />
              <Switch
                name={field.switch_name}
                defaultChecked={field.switch_checked}
                onCheckedChange={checked => handleSwitchToggle(checked)}
              />
              <Input
                type="text"
                defaultValue={field.no_text}
                onChange={e => handleChange(field.id, e)}
                className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                name={field.no_name}
                required
              />
              {showConditionalInputYes ? (
                <div className="flex flex-col space-y-1.5">
                  <Button
                    variant="outline"
                    onClick={handleRemoveConditionalInputYes}
                    className="p-5 py-1 h-auto w-fit rounded-full border-dashed text-red-900/85 border-red-300 space-x-1.5"
                  >
                    <span>Koşullu cevabı kaldır (Sol)</span> <Trash2 size={18} />
                  </Button>
                  <div className="space-y-1.5 bg-slate-50 p-2 border rounded-lg">
                    <Input
                      type="text"
                      id={`yes_reason_${field.id}_title`}
                      value={field.yes_reason_title || ""}
                      onChange={(e) => handleChange(field.id, e)}
                      className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                      name="yes_reason_title"
                      placeholder="Koşullu cevap başlığı girin (Sol)"
                    />
                    <Input
                      type="text"
                      id={`yes_reason_${field.id}_text`}
                      value={field.yes_reason_text || ""}
                      onChange={(e) => handleChange(field.id, e)}
                      className="border p-2 py-0.5 font-medium rounded-md hover:border-gray-400"
                      name="yes_reason_text"
                      placeholder="Varsayılan cevap (Sol)..."
                    />
                  </div>
                </div>
              ) : (
                <Button
                  variant="outline"
                  onClick={handleAddConditionalInputYes}
                  className="p-5 py-1 h-auto w-fit rounded-full border-dashed text-blue-900/85 border-blue-300 space-x-1.5"
                >
                  <span>Koşullu cevap ekle (Sol)</span> <Plus size={18} />
                </Button>
              )}
              <div className="col-span-1"></div>
              {showConditionalInputNo ? (
                <div className="flex flex-col space-y-1.5">
                  <Button
                    variant="outline"
                    onClick={handleRemoveConditionalInputNo}
                    className="p-5 py-1 h-auto w-fit rounded-full border-dashed text-red-900/85 border-red-300 space-x-1.5"
                  >
                    <span>Koşullu cevabı kaldır (Sağ)</span> <Trash2 size={18} />
                  </Button>
                  <div className="space-y-1.5 bg-slate-50 p-2 border rounded-lg">
                    <Input
                      type="text"
                      id={`no_reason_${field.id}_title`}
                      value={field.no_reason_title || ""}
                      onChange={(e) => handleChange(field.id, e)}
                      className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                      name="no_reason_title"
                      placeholder="Koşullu cevap başlığı girin (Sağ)"
                    />
                    <Input
                      type="text"
                      id={`no_reason_${field.id}_text`}
                      value={field.no_reason_text || ""}
                      onChange={(e) => handleChange(field.id, e)}
                      className="border p-2 py-0.5 font-medium rounded-md hover:border-gray-400"
                      name="no_reason_text"
                      placeholder="Varsayılan cevap (Sağ)..."
                    />
                  </div>
                </div>
              ) : (
                <Button
                  variant="outline"
                  onClick={handleAddConditionalInputNo}
                  className="p-5 py-1 h-auto w-fit rounded-full border-dashed text-blue-900/85 border-blue-300 space-x-1.5"
                >
                  <span>Koşullu cevap ekle (Sağ)</span> <Plus size={18} />
                </Button>
              )}
            </div>
          ) : preview ? 
            <div className='ms-5 space-y-1.5 w-full'>
              <Label className="my-auto text-sm font-bold">{field.switch_checked ? field.no_text : field.yes_text}</Label>
              {!field.switch_checked && showConditionalInputYes && (
                <div className="flex flex-col space-y-1.5 ms-4">
                  <div className="opacity-80 font-medium text-sm">
                    {field.yes_reason_title}
                  </div>
                  <Label className="my-auto text-sm font-bold">{field.yes_reason_text || ''}</Label>
                </div>
              )}
              {field.switch_checked && showConditionalInputNo && (
                <div className="flex flex-col space-y-1.5 ms-4">
                  <div className="opacity-80 font-medium text-sm">
                    {field.no_reason_title}
                  </div>
                  <Label className="my-auto text-sm font-bold">{field.no_reason_text || ''}</Label>
                </div>
              )}
            </div>
          : (
            <div className="flex flex-col space-y-2 w-2/3">
              <div className='flex items-center space-x-2'>
                <div className="opacity-80 font-medium text-sm">
                  {field.yes_text}
                </div>
                <Switch
                  name={field.switch_name}
                  defaultChecked={field.switch_checked}
                  onCheckedChange={checked => handleSwitchToggle(checked)}
                />
                <div className="opacity-80 font-medium text-sm">
                  {field.no_text}
                </div>
              </div>
              {!field.switch_checked && showConditionalInputYes && (
                <div className="flex flex-col space-y-1.5 ms-3">
                  <div className="opacity-80 font-medium text-sm">
                    {field.yes_reason_title}
                  </div>
                  <Textarea
                    name='yes_reason_text'
                    value={field.yes_reason_text || ''}
                    onChange={e => handleChange(field.id, e)}
                    className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                    placeholder="..."
                  />
                </div>
              )}
              {field.switch_checked && showConditionalInputNo && (
                <div className="flex flex-col space-y-1.5 ms-3">
                  <div className="opacity-80 font-medium text-sm">
                    {field.no_reason_title}
                  </div>
                  <Textarea
                    name='no_reason_text'
                    value={field.no_reason_text || ''}
                    onChange={e => handleChange(field.id, e)}
                    className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                    placeholder="..."
                  />
                </div>
              )}
            </div>
            )
          }
        </div>

      </div>
    </FieldContainer>
  );
};

const CheckboxInput = ({ editable, preview = false, field, handleChange, handleDelete }) => {
  const [checkboxes, setCheckboxes] = useState(field.checkboxes || []);
  const [showConditionalInputs, setShowConditionalInputs] = useState(
    field.checkboxes.map(checkbox => !!checkbox.reason_title)
  );

  useEffect(() => {
    console.log(field)
  }, [field.checkboxes]);

  const handleAddCheckbox = (e=null) => {
    e && e.preventDefault()
    const newCheckboxes = [...checkboxes, { text: 'Seçenek', checked: false }];
    setCheckboxes(newCheckboxes);
    setShowConditionalInputs([...showConditionalInputs, false])
    handleChange(field.id, { target: { name: 'checkboxes', value: newCheckboxes } });
  };

  useEffect(() => {
    editable && checkboxes.length == 0 && handleAddCheckbox()
  }, []);

  const handleCheckboxChange = (index, e) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].text = e.target.value;
    setCheckboxes(newCheckboxes);
    handleChange(field.id, { target: { name: 'checkboxes', value: newCheckboxes } });
  };

  const handleCheckboxToggle = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index].checked = !newCheckboxes[index].checked;
    setCheckboxes(newCheckboxes);
    handleChange(field.id, { target: { name: 'checkboxes', value: newCheckboxes } });
  };

  const handleRemoveCheckbox = (index) => {
    const newCheckboxes = checkboxes.filter((_, i) => i !== index);
    const newShowConditionalInputs = showConditionalInputs.filter((_, i) => i !== index);
    setCheckboxes(newCheckboxes);
    setShowConditionalInputs(newShowConditionalInputs);
    handleChange(field.id, { target: { name: 'checkboxes', value: newCheckboxes } });
  };

  const handleCheckboxReasonToggle = (e, index, checked) => {
    e.preventDefault();
    const newShowConditionalInputs = [...showConditionalInputs];
    newShowConditionalInputs[index] = checked;
    setShowConditionalInputs(newShowConditionalInputs);

    const newCheckboxes = [...checkboxes];
    if (!checked) {
      newCheckboxes[index].reason_title = '';
      newCheckboxes[index].reason_text = '';
    }
    setCheckboxes(newCheckboxes);
    handleChange(field.id, { target: { name: 'checkboxes', value: newCheckboxes } });
  };

  const handleCheckboxReasonChange = (index, e) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index][e.target.name] = e.target.value;
    setCheckboxes(newCheckboxes);
    handleChange(field.id, { target: { name: 'checkboxes', value: newCheckboxes } });
  };

  return (
    <FieldContainer
      field={field}
      editable={editable}
      handleChange={handleChange}
      handleDelete={handleDelete}
    >
      <div className="flex space-x-2">
        {editable ? (
          <div className='space-y-1 w-full'>
            <div className="grid p-2 gap-4">
              {checkboxes.map((checkbox, index) => {
                const checkboxName = field.checkbox_name + '_' + index;
                const checkboxTextName = field.checkbox_name + '_' + index + '_text';

                return (
                  <div className="flex flex-col space-y-2" key={index}>
                    <div className="flex items-center space-x-5">
                      <div className="flex items-center space-x-3 w-2/3">
                        <Checkbox
                          name={checkboxName}
                          checked={checkbox.checked}
                          onCheckedChange={() => handleCheckboxToggle(index)}
                        />
                        <Input
                          type="text"
                          className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                          name={checkboxTextName}
                          placeholder="Bir yanıt seçeneği girin"
                          value={checkbox.text}
                          onChange={e => handleCheckboxChange(index, e)}
                        />
                        <Delete
                          onClick={() => handleRemoveCheckbox(index)}
                          className={`${checkboxes.length <= 1 && 'disabled'} text-red-600 cursor-pointer`}
                          size={20}
                        />
                      </div>
                      <Button
                        variant="outline"
                        onClick={(e) => handleCheckboxReasonToggle(e, index, !showConditionalInputs[index])}
                        className="p-5 py-1 h-auto w-fit rounded-full border-dashed text-blue-900/85 border-blue-300 space-x-1.5"
                      >
                        <span>Koşullu cevap {showConditionalInputs[index] ? 'kaldır' : 'ekle'}</span> 
                        {showConditionalInputs[index] ? <Trash2 size={18} /> : <Plus size={18} />}
                      </Button>
                    </div>
                    {showConditionalInputs[index] && (
                      <div className="flex flex-col ms-7 space-y-1.5 bg-slate-50 p-2 border rounded-lg">
                        <Input
                          type="text"
                          value={checkbox.reason_title || ""}
                          onChange={(e) => handleCheckboxReasonChange(index, e)}
                          className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                          name="reason_title"
                          placeholder="Koşullu cevap başlığı girin"
                        />
                        <Input
                          type="text"
                          value={checkbox.reason_text || ""}
                          onChange={(e) => handleCheckboxReasonChange(index, e)}
                          className="border p-2 py-0.5 font-medium rounded-md hover:border-gray-400"
                          name="reason_text"
                          placeholder="Varsayılan cevap..."
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <Button
              variant="outline"
              className='p-5 py-1 h-auto w-fit rounded-full border-dashed text-blue-900/85 border-blue-300 space-x-1.5'
              onClick={e => handleAddCheckbox(e)}
            >
              <Plus size={18} />
            </Button>
          </div>
        ) : preview ? (
          <div className="space-y-1 w-full">
            <div className="grid p-2 gap-2">
              {checkboxes.map((checkbox, index) => {
                const checkboxName = field.checkbox_name + "_" + index;

                return (
                  <div
                    className="flex flex-col space-y-2 font-medium text-sm"
                    key={index}
                  >
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id={checkboxName}
                        name={checkboxName}
                        defaultChecked={checkbox.checked}
                        onCheckedChange={() => handleCheckboxToggle(index)}
                      />
                      <label
                        htmlFor={checkboxName}
                        className="opacity-80 cursor-pointer"
                      >
                        {checkbox.text}
                      </label>
                    </div>
                    {checkbox.checked && showConditionalInputs[index] && (
                      <div className="flex flex-col space-y-1.5 ms-4">
                        <div className="opacity-80 font-medium text-sm">
                          {checkbox.reason_title}
                        </div>
                        <Label className="my-auto text-sm font-bold">
                          {checkbox.reason_text || ""}
                        </Label>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className='space-y-1 w-2/3'>
            <div className="grid p-2 gap-2">
              {checkboxes.map((checkbox, index) => {
                const checkboxName = field.checkbox_name + '_' + field.id + '_' + index;

                return (
                  <div className="flex flex-col space-y-2 font-medium text-sm" key={index}>
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id={checkboxName}
                        name={checkboxName}
                        defaultChecked={checkbox.checked}
                        onCheckedChange={() => handleCheckboxToggle(index)}
                      />
                      <label htmlFor={checkboxName} className='opacity-80 cursor-pointer'>
                        {checkbox.text}
                      </label>
                    </div>
                    {checkbox.checked && showConditionalInputs[index] && (
                      <div className="flex flex-col space-y-1.5 ms-3">
                        <div className="opacity-80 font-medium text-sm">
                          {checkbox.reason_title}
                        </div>
                      
                        <Textarea
                          type="text"
                          value={checkbox.reason_text || ""}
                          onChange={(e) => handleCheckboxReasonChange(index, e)}
                          className="border border-dashed p-1 py-0.5 h-6 font-medium rounded hover:border-gray-400"
                          name="reason_text"
                          placeholder="..."
                        />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </FieldContainer>
  );
};


export { TextInput, TextArea, SwitchInput, CheckboxInput }