import { Toaster } from "@/components/ui/toaster";
import { useAuth } from "@/context/authContext";
import { validateToken } from "@/lib/api.service";
import { roles } from "@/lib/roleEnum";
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";

export default function Layout() {
  const [isValid, setIsValid] = useState(null);
  const { user, setUser } = useAuth();
  const location = useLocation();
  console.log("location:", location);

  useEffect(() => {
    const checkToken = async () => {
      try {
        const isValid = await validateToken();
        setIsValid(isValid);
      } catch (error) {
        console.error("Token validation error:", error);
        setIsValid(false);
      }
    };

    checkToken();
  }, []);

  if (isValid === null) {
    return <div>Loading...</div>;
  }

  if (!isValid) {
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return (
    <div className="flex flex-row bg-gray-50-full w-screen ">
      {user.role !== roles.DEFAULTUSER && user.role !== roles.MANAGER && (
        <Sidebar />
      )}
      <div className="w-full ">
        <Outlet />
      </div>
      <Toaster />
    </div>
  );
}
