import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import React, { memo, useEffect, useState } from "react";

export const DebouncedInput = memo(function DebouncedInput({
  placeholder,
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <div className=" w-72 flex max-w-sm items-center gap-2 py-2 sm:py-6  px-2 relative">
      <div className="absolute inset-y-0 left-2 flex items-center pl-3 pointer-events-none">
        <Search size={16} color="gray" />
      </div>
      <Input
        className={"pl-8"}
        placeholder={placeholder}
        id="search"
        type="text"
        value={value ?? ""}
        onChange={(e) => setValue(e.target.value)}
      />
    </div>
  );
});
