import { Separator } from "@/components/ui/separator";
import { useAuth } from "@/context/authContext";
import { roles } from "@/lib/roleEnum";
import { LogOut as SingOut } from "lucide-react";
import { Button } from "./ui/button";

export const Logout = () => {
  const { user, logOut } = useAuth();

  if (user.role === roles.ADMIN) {
    return null;
  }

  return (
    <div className="flex h-5 items-center space-x-2 text-sm">
      <div className=" px-2">{`${user.firstname} ${user.lastname}`}</div>
      <Separator orientation="vertical" />
      <Button variant="ghost" className=" gap-2 " onClick={logOut}>
        <span className="text-xs">Çıkış Yap</span>
        <SingOut size={14} />
      </Button>
    </div>
  );
};
