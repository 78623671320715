import BreadCrumb from "@/components/BreadCrumb";
import { Heading } from "@/components/Heading";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import { useAuth } from "@/context/authContext";
import { roles } from "@/lib/roleEnum";
import { useEffect, useRef, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
const breadcrumbItems = [{ title: "Ayarlar", link: "/dashboard/settings" }];

function Settings() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  let didInit = false;
  const componentRef = useRef();
  const [font, setFont] = useState("12");
  const [testLabel, setTestLabel] = useState("TEST");
  const { user } = useAuth();

  if (user.role === roles.DEFAULTUSER || user.role === roles.MANAGER) {
    navigate("/completed-forms");
  }

  const [qrZoom, setQrZoom] = useState(100);

  const loadDataFromLocalStorage = () => {
    const localSettings = JSON.parse(
      localStorage.getItem("qrSettings") || "{}"
    );
    if (localSettings.font) setFont(localSettings.font);
    if (localSettings.qrZoom) setQrZoom(localSettings.qrZoom);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
          @page {
            size: auto;
            margin: 0;

          }
          @media print {
            body {
              -webkit-print-color-adjust: exact;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100vh;

            }
            header, footer {
              display: none;
            }
          }
        `,
  });

  const saveDataToLocalStorage = (font, qrZoom) => {
    localStorage.setItem("qrSettings", JSON.stringify({ font, qrZoom }));
  };

  const handleSubmit = (e) => {
    saveDataToLocalStorage(font, qrZoom);
    toast({
      title: "Kaydedildi",
      description: "Değişklikler kaydedildi...",
    });
  };
  useEffect(() => {
    if (!didInit) {
      didInit = true;
      loadDataFromLocalStorage();
    }
  }, []);

  return (
    <ScrollArea className="h-full">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb items={breadcrumbItems} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Ayarlar"}
            description={
              "Kurumla ilgili (QR KOD vb..) ayarları uygulayabilirsiniz."
            }
          />
        </div>
        <Separator />

        <div className="grid grid-cols-1  rounded-lg">
          {loader && <span className="px-5 py-5 ">Yükleniyor..</span>}
          {!loader && (
            <div>
              <Tabs defaultValue="account" className="w-full bg-transparent">
                <TabsList className="grid w-[300px] grid-cols-2">
                  <TabsTrigger value="account">QR Kod Ayarı</TabsTrigger>
                  {/* <TabsTrigger value="kurum">...</TabsTrigger> */}
                </TabsList>
                <TabsContent
                  value="account"
                  className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-4"
                >
                  <Card>
                    <CardHeader>
                      <CardTitle>QR Yazdırma Ayarları</CardTitle>
                      <CardDescription>
                        QR Kod yakınlaştırma-uzaklaştırma ve etiket yazısının
                        font ayarlarını uygulayabilirsiniz.
                      </CardDescription>
                    </CardHeader>
                    <CardContent className="">
                      <div className="flex  flex-col  justify-center items-center w-full ">
                        <div className="flex flex-row w-full">
                          <div className="h-full flex flex-col justify-start items-start w-full  rounded-lg  ">
                            <div className="">
                              <div className="grid lg:grid-cols-3 justify-center items-center space-y-2">
                                <label htmlFor="label" className="text-xs">
                                  Test Yazısı (px):{" "}
                                </label>
                                <input
                                  type="text"
                                  id="label"
                                  value={testLabel}
                                  onChange={(e) =>
                                    setTestLabel(e.target?.value)
                                  }
                                  className="w-full border rounded px-2 py-1 col-span-2"
                                  tabIndex={-1}
                                />
                              </div>
                              <div className="grid lg:grid-cols-3 justify-center items-center space-y-2">
                                <label htmlFor="font" className="text-xs">
                                  Font (px):{" "}
                                </label>
                                <input
                                  type="number"
                                  id="font"
                                  value={font}
                                  onChange={(e) => setFont(e.target?.value)}
                                  className="w-full border rounded px-2 py-1"
                                  tabIndex={-1}
                                />
                              </div>
                              <div className="grid lg:grid-cols-3 justify-center items-center space-y-2">
                                <label htmlFor="qrZoom" className="text-xs">
                                  Qr Yakınlaştırma :{" "}
                                </label>
                                <input
                                  type="number"
                                  id="qrZoom"
                                  value={qrZoom}
                                  max={200}
                                  onChange={(e) => setQrZoom(e.target?.value)}
                                  className="w-full border rounded px-2 py-1"
                                  tabIndex={-1}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </CardContent>
                    <CardFooter className={""}>
                      <Button variant="outline" onClick={handleSubmit}>
                        Kaydet
                      </Button>
                    </CardFooter>
                  </Card>
                  <Card className="bg-transparent flex w-full flex-col h-full justify-center items-center border gap-3">
                    <div className="border-[2px] bg-white border-red-400   border-dashed flex justify-center items-center overflow-hidden rounded-lg   ">
                      <div
                        className="flex flex-row  justify-center  w-60mm h-30mm items-center "
                        ref={componentRef}
                      >
                        <div className="flex flex-row pl-2 ">
                          <div
                            className=" text-center text-xs rotate-180 "
                            style={{
                              fontSize: "12px",
                              writingMode: "vertical-lr",
                            }}
                          >
                            {/**formun qr kısa kodu */}
                            TSTK12
                          </div>
                          <QRCode
                            size={qrZoom}
                            value={"test"}
                            className="rounded mx-auto"
                          />
                        </div>

                        <div
                          className=" text-left  flex items-center  whitespace-normal  justify-center   px-4 mt-2  overflow-hiden"
                          style={{
                            fontSize: `${font}px`,
                          }}
                        >
                          {/**formun adı */}
                          {testLabel}
                        </div>
                      </div>
                    </div>
                    <CardFooter className="py-0">
                      <Button variant="outline" onClick={handlePrint}>
                        Yazdır
                      </Button>
                    </CardFooter>
                  </Card>
                </TabsContent>
                {/* <TabsContent value="kurum"></TabsContent> */}
              </Tabs>
            </div>
          )}
        </div>
      </div>
    </ScrollArea>
  );
}

export default Settings;
