import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useState, useEffect } from "react"

const fields = [
  {
    label: "Döküman Kodu",
    type: "text",
    name: "document_code",
    placeholder: "K.XX.FR.00",
    required: true,
  },
  {
    label: "Revizyon No",
    type: "text",
    name: "document_revision_number",
    placeholder: "0",
  },
  /* {
    label: "Sayfa No",
    type: "text",
    name: "document_page_number",
    placeholder: "0",
  }, */
  {
    label: "Yayın Tarihi",
    type: "text",
    name: "document_release_date",
    placeholder: "01/01/2024",
  },
  {
    label: "Revizyon Tarihi",
    type: "text",
    name: "document_revision_date",
    placeholder: "01/02/2024",
  },
  {
    label: "Sayfa No",
    type: "text",
    name: "document_page_number",
    placeholder: "0",
  },
]

const DocumentFields = ({ filldata }) => {
  const [formData, setFormData] = useState(() => {
    const initialFormData = {}
    fields.forEach((field) => {
      initialFormData[field.name] = ""
    })
    return initialFormData;
  })

  useEffect(() => {
    if (filldata) {
      setFormData(filldata);
    }
  }, [filldata])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  return fields.map((field) => (
    <div className="grid space-y-1" key={field.name}>
      <Label htmlFor={field.name}>{field.label}</Label>
      <Input
        type={field.type}
        name={field.name}
        id={field.name}
        placeholder={field.placeholder}
        required={field.required}
        value={formData[field.name]}
        onChange={handleInputChange}
      />
    </div>
  ))
}

const GetDocumentFieldValues = (target) => {
  const documentData = {}
  fields.forEach((field) => {
    documentData[field.name] = target[field.name].value
  })
  return documentData
}

export { DocumentFields, GetDocumentFieldValues }
