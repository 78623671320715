import { TextInput, TextArea, SwitchInput } from "@/components/formfields/Inputs"
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { Button } from "@/components/ui/button"
import { Calculator, CircleCheckBig, ScrollText, SquareCheckBig, Star, Stars, Text, ToggleLeft } from "lucide-react"
import { CheckboxInput } from "./formfields/Inputs";

const inputTypes = [
  {
    element: 'Form açıklaması',
    icon: <Stars size={16} />,
    type: 'form_description_textbox',
    title_name: 'form_description_textbox_title_value',
    input_name: 'form_description_textbox_value',
  },
  {
    element: 'Kısa yanıt',
    icon: <Text size={16} />,
    type: 'text',
    title_name: 'input_title_value',
    input_name: 'input_value',
  },
  {
    element: 'Paragraf',
    icon: <ScrollText size={16} />,
    type: 'textbox',
    title_name: 'textbox_title_value',
    input_name: 'textbox_value',
  },
  {
    element: 'Sayısal yanıt',
    icon: <Calculator size={16} />,
    type: 'number',
    title_name: 'input_title_value',
    input_name: 'input_value',
  },
  {
    element: 'Kaydırmalı seçenek',
    icon: <ToggleLeft size={16} />,
    type: 'switch',
    title_name: 'switch_title',
    switch_name: 'switch_checked',
    yes_text: 'Evet',
    yes_name: 'yes',
    no_text: 'Hayır',
    no_name: 'no',
  },
  {
    element: 'Onay kutuları',
    icon: <SquareCheckBig size={16} />,
    type: 'checkbox',
    title_name: 'checkbox_title',
    checkbox_name: 'checkbox_field',
    checkboxes: [],
  },
  /* {
    element: 'Çoktan seçmeli',
    icon: <CircleCheckBig size={16} />,
    type: 'radio'
  } */
]

const DraggableInput = (fieldData) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'input',
    item: fieldData,
    collect: monitor => ({
      isDragging: !!monitor.isDragging()
    })
  }));

  return (
    <Button 
      ref={drag} 
      type="button"
      variant="ghost" 
      className="w-full border border-dashed space-x-2 !justify-normal" 
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {fieldData.icon} <span>{fieldData.element}</span>
    </Button>
  );
};

const DroppableForm = ({ onDrop, formFields, setFormFields }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'input',
    drop: onDrop,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  // Update to handle changes by directly modifying the field's value state
  const handleChange = (id, e) => {
    console.log(e)
    const { name, value, type, checked } = e.target;
    console.log(name, value, type, checked)
    const finalValue = type === 'checkbox' ? checked : value;
    setFormFields(fields =>
      fields.map(field =>
        field.id === id ? { ...field, [name]: finalValue } : field
      )
    );
  }

  // Delete operation remains the same
  const handleDelete = (id, e) => {
    e.stopPropagation();
    setFormFields(fields => {
      const remainingFields = fields.filter(field => field.id !== id);
      return remainingFields.map((field, index) => ({
        ...field,
        id: index + 1
      }));
    });
  };


  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={`border border-dashed border-green-300 p-2 space-y-4 rounded-xl ${isActive && 'border-green-900'} ${canDrop && 'border-green-400'}`}>
      {formFields.length === 0 && (
        <div className='p-1 px-2 rounded-lg bg-white border border-dashed text-gray-500'>
          {isActive ? 'Komponenti buraya bırakın' : 'Form alanına bir komponent ekleyin...'}
        </div>
      )}
      {formFields.map(field => {
        console.log(field)
        if (field.type == 'form_description_textbox') return (
          <TextArea editable={true} field={field} handleChange={handleChange} handleDelete={handleDelete} />
        )
        if (field.type == 'text' || field.type == 'number') return (
          <TextInput editable={true} field={field} handleChange={handleChange} handleDelete={handleDelete} />
        )
        if (field.type == 'textbox') return (
          <TextArea editable={true} field={field} handleChange={handleChange} handleDelete={handleDelete} />
        )
        if (field.type == 'switch') return (
          <div key={field.id} className='p-1 rounded-lg bg-white'>
            <SwitchInput editable={true} field={field} handleChange={handleChange} handleDelete={handleDelete} />
          </div>
        )
        if (field.type == 'checkbox') return (
          <div key={field.id} className='p-1 rounded-lg bg-white'>
            <CheckboxInput editable={true} field={field} handleChange={handleChange} handleDelete={handleDelete} />
          </div>
        )
        if (field.type == 'radio') return (
          <div key={field.id} className='p-1 rounded-lg bg-white'>
            radio
          </div>
        )
      })}
    </div>
  );
};

export { DraggableInput, DroppableForm, inputTypes }