import api from "@/lib/api.service";

const URL = "/inspectform";

const transformData = (response) => {
  return [
    ["Durum", "Sayısı"],
    ["Toplam Doldurulan Form Sayısı", response.status_summary.ALL || 0],
    ["Onay Bekleyen Formlar", response.status_summary.TOAPPROVAL || 0],
    ["Reddedilen Formlar", response.status_summary.REJECTED || 0],
    ["İşlenmemiş Formlar", 0],
  ];
};

export const dashboardSummary = async (filter) => {
  const { data } = await api.get(`${URL}/status-summary?filter=${filter}`);
  if (data.status) {
    console.log(data);
    return transformData(data);
  }
};
