import api from "@/lib/api.service";

const URL = "/inspectform";

export const getAllCompletedForms = async () => {
  const { data } = await api.get(`${URL}/list`);
  console.log(data);
  return data;
};

export const getbyFormIdCompletedForms = async (formId) => {
  const { data } = await api.get(`${URL}/get?formID=${formId}`);
  return data;
};

export const createCompletedForms = async (form) => {
  const { data } = await api.post(URL, form);
  return data;
};

export const updateCompletedForms = async (id, form) => {
  const { data } = await api.put(`${URL}/update?formId=${id}`, form);
  return data;
};

export const deleteCompletedForm = async (id) => {
  const { data } = await api.post(`${URL}/delete`, { _id: id });
  return data;
};

export const archiveCompletedForm = async (_id, archiving_user_id) => {
  const { data } = await api.post(`${URL}/archive`, { _id, archiving_user_id });
  return data;
};

export const formReddetRequest = async (id) => {
  const { data } = await api.post(`${URL}/status/reject`, { _id: id });
  return data;
};
export const formOnaylaRequest = async (id) => {
  const { data } = await api.post(`${URL}/status/success`, { _id: id });
  return data;
};

export const formHataliRequest = async (id) => {
  const { data } = await api.post(`${URL}/status/reject`, { _id: id });
  return data;
};
export const formRevizeTalepRequest = async (id) => {
  const { data } = await api.post(`${URL}/status/needrevise`, { _id: id });
  return data;
};
export const formRevizeOnayRequest = async (id) => {
  const { data } = await api.post(`${URL}/status/inrevise`, { _id: id });
  return data;
};
