// WorkGroupField.js
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { apiconfig } from "@/lib/apiconfig";
import { Delete, Trash2, X } from 'lucide-react';

const WorkGroupField = ({
  placeholder = "Bir çalışma grubu seç",
  name,
  required = true,
  defaultValue,
  onValueChange,
  canBeLeftBlank = false
}) => {
  const [workGroups, setWorkGroups] = useState([]);
  const [selectedWorkGroup, setSelectedWorkGroup] = useState(defaultValue);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(apiconfig().url + '/api/workgroup/list')
        console.log(response.data)
        setWorkGroups(response.data)
      } catch (error) {
        console.error('Veri çekme hatası', error)
      }
    }

    fetchData()
  }, []);

  const handleChange = (value) => {
    setSelectedWorkGroup(value);
    if (onValueChange) {
      onValueChange(value);
    }
  };

  const handleClear = () => {
    setSelectedWorkGroup('');
    if (onValueChange) {
      onValueChange('');
    }
  };

  return (
    <div className="flex items-center space-x-2 w-full">
      <Select name={name} required={required} value={selectedWorkGroup} onValueChange={handleChange}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            {workGroups.map(workGroup => (
              <SelectItem key={workGroup._id} value={workGroup._id}>
                {workGroup.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      {canBeLeftBlank && selectedWorkGroup && (
        <button
          type="button"
          className={`flex items-center`}
          onClick={handleClear}
        >
          <Delete size={18} className="text-slate-500" />
        </button>
      )}
    </div>
  );
};

export default WorkGroupField;
