import { ChevronRight } from "lucide-react";
import { useEffect, useState } from "react";

import { default as OtherQrCode } from "@/components/QrCode";
import { Button } from "@/components/ui/button";
import { CardContent, CardFooter } from "@/components/ui/card";
import { apiconfig } from "@/lib/apiconfig";
import { useNavigate } from "react-router-dom";

const GenerateQrCode = ({ formQrCode, name, children }) => {
  const navigate = useNavigate();
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  useEffect(() => {
    setQrCodeUrl(apiconfig().url + `/fill-form/${formQrCode}`);
  }, [formQrCode]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate("/dashboard");
  };

  const handleGoToForm = (e) => {
    e.preventDefault();
    navigate("/fill-form/" + formQrCode);
  };

  return (
    <form onSubmit={() => {}} className="grid grid-cols-1 ">
      {children}
      <CardContent id="printableArea">
        {qrCodeUrl && (
          <OtherQrCode
            qrCodeUrl={qrCodeUrl}
            qrCode={formQrCode}
            formName={name}
          />
        )}
      </CardContent>
      <CardFooter className="space-x-4 flex justify-between">
        <Button variant="outline" onClick={handleGoToForm} className="flex-1">
          Forma Git
          <ChevronRight className="ms-1.5" size={18} />
        </Button>
      </CardFooter>
    </form>
  );
};

export default GenerateQrCode;
