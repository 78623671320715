import { Cross2Icon } from "@radix-ui/react-icons";

import { Button } from "@/components/ui/button";

import { DebouncedInput } from "../DebouncedInput";
import { DataTableFacetedFilter } from "./data-table-faceted-filter";
import { dateFilters } from "./data";

export function DataTableToolbar({ table, work_groups }) {
  const isFiltered = table.getState().columnFilters.length > 0;
  const onChange = (event) => {
    table.getColumn("name")?.setFilterValue(event);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-1 items-center space-x-2">
        <DebouncedInput
          placeholder="Formlarda ara.."
          value={table.getColumn("name")?.getFilterValue() ?? ""}
          onChange={onChange}
          className="h-8 w-[150px] lg:w-[250px]"
        />
        {table.getColumn("work_group") && (
          <DataTableFacetedFilter
            column={table.getColumn("work_group")}
            title="Çalışma Grubu"
            options={work_groups}
          />
        )}
        {table.getColumn("createdAt") && (
          <DataTableFacetedFilter
            column={table.getColumn("createdAt")}
            title="Tarih"
            options={dateFilters}
          />
        )}
        {/* {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )} */}
        {isFiltered && (
          <Button
            variant="ghost"
            onClick={() => table.resetColumnFilters()}
            className="h-8 px-2 lg:px-3"
          >
            Filtreyi Kaldır
            <Cross2Icon className="ml-2 h-4 w-4" />
          </Button>
        )}
      </div>
    </div>
  );
}
