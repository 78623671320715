import DataTableColumnHeader from "@/components/editable-forms-data-table/data-table-column-header";
import DataTableRowActions from "@/components/editable-forms-data-table/data-table-row-actions";
import { roles } from "@/lib/roleEnum";
import { convertStatus } from "@/lib/utils";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { BookOpenText, CalendarClock, UserPlus } from "lucide-react";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import turkishStrings from "react-timeago/lib/language-strings/tr";
const formatter = buildFormatter(turkishStrings);

export const getAllFormsColumns = ({
  onPrintQr,
  onEdit,
  onFillForm,
  onDelete,
  user,
}) => [
  {
    accessorKey: "name",
    header: (props) => {
      return (
        <div className="flex gap-2  items-center justify-start text-normal ">
          <BookOpenText size={16} />
          <p>Form Adı</p>
        </div>
      );
    },
    cell: ({ row }) => {
      const { text, color } = convertStatus(row.original.status);
      return (
        <div className="w-64  flex justify-start items-center gap-2 text-[13px]  ">
          {/* <div className={`h-6 w-2  rounded-full ${color}`} /> */}
          <div className="">{row.original.name}</div>
        </div>
      );
    },
    // footer: (props) => {
    //   const totalForms = props.table.getRowModel().rows.length;
    //   return (
    //     <div className=" h-6">
    //       <span>Toplam :</span>
    //       <span className="font-bold">{totalForms}</span>
    //     </div>
    //   );
    // },
  },
  {
    accessorKey: "creator_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserPlus size={16} />
          <span>Formu Oluşturan</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const creator_user = row.original?.creator_user;
      const name = creator_user
        ? creator_user.firstname + " " + creator_user.lastname
        : "Bilinmiyor";
      return <div className="flex justify-center items-center ">{name}</div>;
    },
  },
  {
    accessorFn: (d) => (d.work_group ? d.work_group["name"] : "Belirtilmemiş"),
    id: "work_group",
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },

    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-start">
          <UserPlus size={16} />
          <span>Çalışma Grubu</span>
        </div>
      );
    },
    cell: ({ row }) => (
      <span className="">{row.original.work_group?.name}</span>
    ),
  },

  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader
        className="text-left "
        column={column}
        title="Oluşturma Zamanı"
      />
    ),
    cell: (row) => {
      const date = new Date(row.getValue());
      return (
        <div className="w-full flex font-medium pl-3">
          {format(date, "dd.MM.yyyy HH:mm", { locale: tr })}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const date = new Date(row.getValue(id))
      const today = new Date()
      
      // Bugünün başlangıcı
      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
      
      // Bu haftanın başlangıcı (Pazartesi)
      const startOfWeek = new Date(today)
      startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))
      startOfWeek.setHours(0, 0, 0, 0)
      
      // Bu ayın başlangıcı
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      
      // Bu yılın başlangıcı
      const startOfYear = new Date(today.getFullYear(), 0, 1)
      
      // Geçen haftanın başlangıcı ve bitişi
      const startOfLastWeek = new Date(startOfWeek)
      startOfLastWeek.setDate(startOfLastWeek.getDate() - 7)
      const endOfLastWeek = new Date(startOfWeek)
      endOfLastWeek.setMilliseconds(-1)
      
      // Geçen ayın başlangıcı ve bitişi
      const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
      
      // Geçen yılın başlangıcı ve bitişi
      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1)
      const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59, 999)
      
      return value.includes("today") && date >= startOfToday ||
             value.includes("week") && date >= startOfWeek ||
             value.includes("month") && date >= startOfMonth ||
             value.includes("year") && date >= startOfYear ||
             value.includes("lastWeek") && date >= startOfLastWeek && date <= endOfLastWeek ||
             value.includes("lastMonth") && date >= startOfLastMonth && date <= endOfLastMonth ||
             value.includes("lastYear") && date >= startOfLastYear && date <= endOfLastYear
    },
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <div className="flex gap-1 items-center justify-start">
        <CalendarClock size={16} />
        <DataTableColumnHeader
          className=" "
          column={column}
          title="Güncellenme Zamanı"
        />
      </div>
    ),
    cell: (row) => {
      const date = new Date(row.getValue());
      return (
        <div className="w-full flex font-medium pl-2">
          {format(date, "dd.MM.yyyy HH:mm", { locale: tr })}
        </div>
      );
    },
  },

  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        onPrintQr={onPrintQr}
        onEdit={onEdit}
        onFillForm={onFillForm}
        onDelete={user.role === roles.ADMIN && onDelete}
      />
    ),
    size: 20,
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];
