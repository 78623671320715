import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Printer, View } from "lucide-react";

const DataTableRowActions = ({ actions, row, onPreview, onPrint }) => {
  return (
    <div className="w-full flex gap-2 justify-center items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onPreview(row.original)}
            >
              <View size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu İncele</p>
          </TooltipContent>
        </Tooltip>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onPrint(row.original)}
            >
              <Printer size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu Yazdır</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default DataTableRowActions;
