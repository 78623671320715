import { Toaster } from "@/components/ui/toaster";
import { useToast } from "@/components/ui/use-toast";
import api from "@/lib/api.service";
import { updateLastLoginAt } from "@/lib/user.service";
import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const { toast } = useToast();

  const [token, setToken] = useState(localStorage.getItem("token" | ""));
  const navigate = useNavigate();

  const location = useLocation();

  // const getPreviousPath = (location) => {
  //   if (location.state?.from?.state?.from) {
  //     return location.state.from.state.from;
  //   }
  //   return location.state?.from || "/";
  // };

  const loginAction = async (username, password) => {
    try {
      const response = await api.post("/user/login", { username, password });

      if (response.data.status) {
        const { token, user } = response.data;

        if (user?.lastLoginAt == null) {
          // eğer kullanıcı ilk kez giriş yapıyorsa, şifresini güncellemesi gerekiyor.
          // bu yüzden return edilerek şifre güncelleme sayfasına yönlendiriliyor.
          return { status: true, renewPassword: true, user: user }
        } else {
          await updateLastLoginAt(user.username, password)
        }

        localStorage.setItem("token", token);
        localStorage.setItem("_id", user._id);
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);

        const fromPath = location.state?.from || "/";
        console.log("auth context location:", location.state?.from);

        navigate(fromPath, { replace: true });
        return { status: true, message: "Giriş yapıldı" }
      } else {
        return { status: false, message: response.data?.message || "Giriş yapılamadı" }
      }
    } catch (error) {
      console.error("Login Error:", error);
      return { status: false, message: error.response.data?.message }
    }
  };
  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("token");
    localStorage.removeItem("_id");
    localStorage.removeItem("username");
    localStorage.removeItem("user");

    navigate("/logout");
  };
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, setUser, loginAction, logOut }}>
      {children}
      <Toaster />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
