import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { queryKeys } from "@/lib/queryKey.factory";
import { getAllWorkingGroups } from "@/lib/workingGroups.service";
import { useQuery } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { DataTablePagination } from "./data-table-pagination";
import { DataTableToolbar } from "./data-table-toolbar";

const DEFAULT_REACT_TABLE_COLUMN_WIDTH = 100;

const DataTable = ({ data, user, columns, pagination, setPagination }) => {
  const [groupList, setGroupList] = useState([]);

  const { data: workGroups, isFetching } = useQuery({
    queryKey: queryKeys.fetchWorkingGroups.all,
    queryFn: () => getAllWorkingGroups(),
    initialData: [],
    staleTime: 0,
  });

  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnFilters,
      rowSelection,
      pagination, // Pagination durumunu context'ten aldık
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const formatWorkingGroups = (workGroups) => {
    const data = workGroups.map((group) => ({
      value: group.name,
      label: group.name,
      icon: "",
    }));
    return data;
  };

  useEffect(() => {
    const formatGroup = formatWorkingGroups(workGroups);
    setGroupList(formatGroup);
  }, [workGroups]);

  return (
    <div className="w-full flex flex-col ">
      <div className="w-full flex justify-start">
        <DataTableToolbar table={table} work_groups={groupList} />
      </div>

      <div className="rounded-md border overflow-hidden bg-white">
        <Table>
          <TableHeader className="bg-gray-200">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className="">
                {headerGroup.headers.map((header) => {
                  const styles =
                    header.getSize() !== DEFAULT_REACT_TABLE_COLUMN_WIDTH
                      ? { width: `${header.getSize()}px` }
                      : {};

                  return (
                    <TableHead
                      key={header.id}
                      style={styles}
                      className="font-medium"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} className="">
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className="">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="">
                  Kayıt bulunamadı
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <div className="h-0.5 w-full bg-gray-200" />
        <DataTablePagination table={table} />
      </div>
    </div>
  );
};

export default DataTable;
