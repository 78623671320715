export const queryKeys = {
  // doldurulan formlar
  fetchCompletedForms: {
    all: ["fetchCompletedForms"],
  },
  // Editable form key
  fetchEditableForms: {
    all: ["fetchEditableForms"],
  },
  // tek user getirmek için key
  fetchUser: {
    single: ["fetchUser"], // tek üser getirmek için
  },
  // Çalışma grupları
  fetchWorkingGroups: {
    all: ["fetchWorkingGroups"],
  },
  // arşivlenen formlar
  fetchArchivedForms: {
    all: ["fetchArchivedForms"],
  },
};
