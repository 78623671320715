import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { Calendar as CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils"; // cn fonksiyonunu içe aktardığınızdan emin olun

const fields = [
  {
    name: "anon_people_can_fill",
    text: "Formu anonim kişiler doldurabilir",
    type: "switch"
  },
  {
    name: "responsible_user_required",
    text: "Form sorumlusunun seçilme zorunluğu",
    type: "switch"
  },
  {
    name: "control_period",
    render: (value, onChange, disabled) => (
      <div className="space-y-2">
        <label
          htmlFor="control_period"
          className="text-sm cursor-pointer font-medium leading-none"
        >
          Kontrol periyotu
        </label>
        <Select value={value} onValueChange={onChange} disabled={disabled}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Doldurulması gereken aralık" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="1">Günlük kontrol</SelectItem>
            <SelectItem value="7">Haftalık kontrol (7 gün)</SelectItem>
            <SelectItem value="15">15 Günde bir kontrol (15 gün)</SelectItem>
            <SelectItem value="28">Aylık kontrol (28 gün)</SelectItem>
            <SelectItem value="29">Aylık kontrol (29 gün)</SelectItem>
            <SelectItem value="30">Aylık kontrol (30 gün)</SelectItem>
            <SelectItem value="31">Aylık kontrol (31 gün)</SelectItem>
            <SelectItem value="365">Yıllık kontrol (365 gün)</SelectItem>
          </SelectContent>
        </Select>
      </div>
    ),
  },
  {
    name: "control_period_start_date",
    render: (value, onChange, disabled) => (
      <div className="space-y-2">
        <label
          htmlFor="control_period_start_date"
          className="text-sm cursor-pointer font-medium leading-none"
        >
          Kontrol periyotu başlangıç tarihi
        </label>
        <Popover className="w-full">
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "w-full justify-start text-left font-normal",
                !value && "text-muted-foreground"
              )}
              disabled={disabled}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {value ? format(new Date(value), "PPP", { locale: tr }) : <span>İlk kontrol tarihi</span>}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0">
            <Calendar
              mode="single"
              selected={value ? new Date(value) : undefined}
              onSelect={(date) => onChange(date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().split('T')[0] : "")}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
    ),
  },
];

const FormSettings = forwardRef(({ filldata }, ref) => {
  const [formData, setFormData] = useState(() => {
    const initialFormData = {};
    fields.forEach((field) => {
      initialFormData[field.name] = field.type === "switch" ? false : "";
    });
    return initialFormData;
  });

  useEffect(() => {
    if (filldata) {
      setFormData(filldata);
    }
  }, [filldata]);

  const handleSwitchChange = (checked, field) => {
    const updatedFormData = { ...formData, [field.name]: checked };
    setFormData(updatedFormData);
  };

  const handleInputChange = (event, field) => {
    const value = event.target ? event.target.value : event;
    setFormData({ ...formData, [field.name]: value });
  };

  useImperativeHandle(ref, () => ({
    getSettingsValues() {
      return formData;
    },
  }));

  const isAnonFillEnabled = formData.anon_people_can_fill;

  return fields.map((field, index) => (
    <div key={field.name} className="space-y-3">
      {field?.render ? (
        field.render(formData[field.name], (event) => handleInputChange(event, field), isAnonFillEnabled)
      ) : (
        <div className="flex items-center space-x-2">
          <Switch
            id={field.name}
            name={field.name}
            checked={formData[field.name]}
            onCheckedChange={(checked) => handleSwitchChange(checked, field)}
            disabled={isAnonFillEnabled && field.name !== "anon_people_can_fill"}
          />
          <label
            htmlFor={field.name}
            className="text-sm cursor-pointer font-medium leading-none"
          >
            {field.text}
          </label>
        </div>
      )}
    </div>
  ));
});

export { FormSettings };
