import { statuses } from "@/components/completed-forms-data-table/data";
import DataTableColumnHeader from "@/components/completed-forms-data-table/data-table-column-header";
import DataTableRowActions from "@/components/completed-forms-data-table/data-table-row-actions";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip"
import { convertStatus } from "@/lib/utils";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { ArrowUpFromLine, BookOpenText, CalendarClock, ChevronsUp, FileUp, GalleryHorizontal, GalleryHorizontalEnd, Upload, UserCheck, UserPlus } from "lucide-react";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import turkishStrings from "react-timeago/lib/language-strings/tr";
const formatter = buildFormatter(turkishStrings);

export const getCompletedFormsColumns = ({ actions, onPreview, onPrint }) => {
  return [
    {
      accessorKey: "name",
      header: (props) => {
        return (
          <div className="flex  gap-2  items-center justify-start ">
            <BookOpenText size={16} />
            <span>Form Adı</span>
          </div>
        );
      },
      cell: ({ row }) => {
        const { text, color } = convertStatus(row.original.status);
        const { name: workGroupName = "Unknown" } =
          row.original.filled_form?.work_group ?? {};

        return (
          <div className="w-72 h-full grid grid-cols-1 gap-2">
            <div className="flex flex-1 gap-2 items-center justify-start">
              {row.original.isExternalForm ? (
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="flex space-x-0.5 rounded-full ps-1.5 pe-2.5 py-0.5 text-xs font-semibold bg-primary text-primary-foreground hover:bg-primary/80">
                        <ChevronsUp size={17} />
                        <div>DOSYA</div>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>
                        Bu form harici dosya olarak sisteme yüklenmiş.
                      </p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              ) : <div className={`h-2 w-2  rounded-full ${color}`} />}
              
              {row.original.name}
            </div>
            {/* <div
            className={`px-2 py-0.5  bg-slate-100 text-[11px] rounded-full text-slate-400 hover:text-slate-600`}
          >
            {workGroupName}
          </div> */}
          </div>
        );
      },
      // footer: (props) => {
      //   const totalForms = props.table.getRowModel().rows.length;
      //   return (
      //     <div className=" h-6">
      //       <span>Toplam :</span>
      //       <span className="font-bold">{totalForms}</span>
      //     </div>
      //   );
      // },
    },
    {
      accessorFn: (d) => d.filled_form?.work_group["name"],
      id: "work_group",
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },

      header: (props) => {
        return (
          <div className="flex gap-2 items-center justify-center">
            <UserPlus size={16} />
            <span>Çalışma Grubu</span>
          </div>
        );
      },
      cell: ({ row }) => (
        <div
          className={`px-2 py-1 flex justify-center items-center rounded-full text-sky-600 hover:text-sky-900`}
        >
          {row.original?.external_form_work_group // harici form ise, yüklenen formun çalışma grubu
            ? row.original?.external_form_work_group?.name
            : row.original?.filled_form?.work_group?.name /* yoksa gerçek formun çalışma grubu */}
        </div>
      ),
    },
    {
      accessorKey: "filler_user",
      header: (props) => {
        return (
          <div className="flex gap-2 items-center justify-center">
            <UserPlus size={16} />
            <span>Formu Dolduran</span>
          </div>
        );
      },
      cell: ({ row }) => {
        const filler_user = row.original.filler_user;
        const filler_user_is_anon = row.original.filler_user_is_anon;
        const name = filler_user_is_anon
          ? "Anonim"
          : filler_user?.firstname + " " + filler_user?.lastname;
        return <div className="flex justify-center items-center">{name}</div>;
      },
    },
    {
      accessorKey: "responsible_user",
      header: (props) => {
        return (
          <div className="flex gap-2 items-center justify-center">
            <UserCheck size={16} />
            <span>Form Sorumlusu</span>
          </div>
        );
      },
      cell: ({ row }) => {
        const responsible_user = row.original?.responsible_user;
        if (!responsible_user) return "-";
        return responsible_user?.firstname + " " + responsible_user?.lastname;
      },
    },
    {
      accessorKey: "status",
      header: ({ column }) => (
        <DataTableColumnHeader
          className="text-center"
          column={column}
          title="Durum"
        />
      ),
      cell: ({ row }) => {
        if (row.original.isExternalForm) {
          return (
            <div className="flex w-full items-center">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="rounded-full px-2.5 py-0.5 text-xs font-semibold bg-primary text-primary-foreground hover:bg-primary/80">
                      <div>Doldurulmuş</div>
                    </div>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>
                      Bu form harici dosya olarak sisteme yüklenmiş.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          );
        } else {
          const status = statuses.find(
            (status) => status.value === row.getValue("status")
          );
          const { text, color } = convertStatus(row.original.status);
          if (!status) {
            return null;
          }

          return (
            <div className="flex w-full items-center">
              {status.icon && (
                <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
              )}
              <div className="w-full flex tems-center ">
                <Badge variant="outline" className={color}>
                  {status.label}
                </Badge>
              </div>
            </div>
          );
        }
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
    },

    //   {
    //     accessorKey: "createdAt",
    //     header: ({ column }) => (
    //       <DataTableColumnHeader
    //         className="text-left "
    //         column={column}
    //         title="Oluşturma Zamanı"
    //       />
    //     ),
    //     cell: (row) => {
    //       return (
    //         <div className="w-full flex  text-xs font-bold pl-3 ">
    //           <TimeAgo date={row.getValue()} formatter={formatter} />
    //         </div>
    //       );
    //     },
    //   },
    {
      accessorKey: "updatedAt",
      header: ({ column }) => (
        <div className="flex gap-1 items-center justify-center">
          <CalendarClock size={16} />
          <DataTableColumnHeader column={column} title="Güncellenme Zamanı" />
        </div>
      ),
      cell: (row) => {
        const date = new Date(row.getValue());
        return (
          <div className="w-full flex justify-center items-center font-medium">
            {format(date, "dd.MM.yyyy HH:mm", { locale: tr })}
          </div>
        );
      },
      filterFn: (row, id, value) => {
        const date = new Date(row.getValue(id))
        const today = new Date()
        
        // Bugünün başlangıcı
        const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
        
        // Bu haftanın başlangıcı (Pazartesi)
        const startOfWeek = new Date(today)
        startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))
        startOfWeek.setHours(0, 0, 0, 0)
        
        // Bu ayın başlangıcı
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
        
        // Bu yılın başlangıcı
        const startOfYear = new Date(today.getFullYear(), 0, 1)
        
        // Geçen haftanın başlangıcı ve bitişi
        const startOfLastWeek = new Date(startOfWeek)
        startOfLastWeek.setDate(startOfLastWeek.getDate() - 7)
        const endOfLastWeek = new Date(startOfWeek)
        endOfLastWeek.setMilliseconds(-1)
        
        // Geçen ayın başlangıcı ve bitişi
        const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
        const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        
        // Geçen yılın başlangıcı ve bitişi
        const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1)
        const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59, 999)
        
        return value.includes("today") && date >= startOfToday ||
               value.includes("week") && date >= startOfWeek ||
               value.includes("month") && date >= startOfMonth ||
               value.includes("year") && date >= startOfYear ||
               value.includes("lastWeek") && date >= startOfLastWeek && date <= endOfLastWeek ||
               value.includes("lastMonth") && date >= startOfLastMonth && date <= endOfLastMonth ||
               value.includes("lastYear") && date >= startOfLastYear && date <= endOfLastYear
      },
    },

    //   {
    //     accessorKey: "balance",
    //     header: ({ column }) => (
    //       <DataTableColumnHeader
    //         className="text-right"
    //         column={column}
    //         title="Balance"
    //       />
    //     ),
    //     cell: ({ row }) => <div className="text-right">cell</div>,
    //     footer: (props) => {
    //       //   const totalBalance = props.table
    //       //     .getRowModel()
    //       //     .rows.reduce(
    //       //       (sum, bankAccountRow) => sum + bankAccountRow.original.balance,
    //       //       0
    //       //     );
    //       return <div className="text-right">total</div>;
    //     },
    // },

    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <DataTableRowActions
            row={row}
            actions={actions}
            onPreview={onPreview}
            onPrint={onPrint}
          />
        );
      },
      size: 50,
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
    },
  ];
};
