import DataTableColumnHeader from "@/components/archived-forms-data-table/data-table-column-header";
import DataTableRowActions from "@/components/archived-forms-data-table/data-table-row-actions";
import { Badge } from "@/components/ui/badge";
import { convertStatus } from "@/lib/utils";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { parseISO } from "date-fns";
import { BookOpenText, CalendarClock, UserCheck, UserPlus } from "lucide-react";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import turkishStrings from "react-timeago/lib/language-strings/tr";

const formatter = buildFormatter(turkishStrings);

const dateBetweenFilterFn = (rows, id, filterValue) => {
  const { from, to } = filterValue;
  return rows.filter((row) => {
    const rowDate = parseISO(row.values[id]);
    return rowDate >= from && rowDate <= to;
  });
};

export const getAllArchivedFormsColumns = ({ actions, onPreview, onPrint }) => [
  {
    accessorKey: "name",
    header: (props) => {
      return (
        <div className="flex  gap-2  items-center justify-start ">
          <BookOpenText size={16} />
          <span>Form Adı</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const { text, color } = convertStatus(row.original.status);
      return (
        <div className="w-64  flex justify-start items-center gap-2 text-[13px]  ">
          {/* <div className={`h-6 w-2  rounded-full ${color}`} /> */}
          <div className="">{row.original.name}</div>
        </div>
      );
    },
    // footer: (props) => {
    //   const totalForms = props.table.getRowModel().rows.length;
    //   return (
    //     <div className=" h-6">
    //       <span>Toplam :</span>
    //       <span className="font-bold">{totalForms}</span>
    //     </div>
    //   );
    // },
  },

  {
    accessorFn: (d) => d.filled_form.work_group["name"],
    id: "work_group",
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },

    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-start">
          <UserPlus size={16} />
          <span>Çalışma Grubu</span>
        </div>
      );
    },
    cell: ({ row }) => row.original.filled_form.work_group?.name,
  },
  {
    accessorKey: "filler_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserPlus size={16} />
          <span>Formu Dolduran</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const filler_user = row.original.filler_user;
      const filler_user_is_anon = row.original.filler_user_is_anon;
      const name = filler_user_is_anon
        ? "Anonim"
        : filler_user?.firstname + " " + filler_user?.lastname;
      return <div className="flex justify-center items-center">{name}</div>;
    },
  },
  {
    accessorKey: "responsible_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserCheck size={16} />
          <span>Form Sorumlusu</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const responsible_user = row.original?.responsible_user;
      if (!responsible_user) return "-";
      return responsible_user?.firstname + " " + responsible_user?.lastname;
    },
  },
  {
    accessorKey: "archiving_user",
    header: (props) => {
      return (
        <div className="flex gap-2 items-center justify-center">
          <UserCheck size={16} />
          <span>Formu Arşivleyen</span>
        </div>
      );
    },
    cell: ({ row }) => {
      const archiving_user = row.original?.archiving_user;
      if (!archiving_user) return "-";
      return (
        <Badge variant={"secondary"} className={"text-center text-green-800"}>
          {archiving_user?.firstname + " " + archiving_user?.lastname}
        </Badge>
      );
    },
  },
  // {
  //   accessorKey: "status",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader
  //       className="text-center"
  //       column={column}
  //       title="Durum"
  //     />
  //   ),
  //   cell: ({ row }) => {
  //     const status = statuses.find(
  //       (status) => status.value === row.getValue("status")
  //     );
  //     const { text, color } = convertStatus(row.original.status);
  //     if (!status) {
  //       return null;
  //     }

  //     return (
  //       <div className="flex w-full items-center">
  //         {status.icon && (
  //           <status.icon className="mr-2 h-4 w-4 text-muted-foreground" />
  //         )}
  //         <div className="w-full flex tems-center text-xs ">
  //           <Badge variant="outline" className={color}>
  //             {status.label}
  //           </Badge>
  //         </div>
  //       </div>
  //     );
  //   },
  //   filterFn: (row, id, value) => {
  //     return value.includes(row.getValue(id));
  //   },
  // },

  // {
  //   accessorKey: "createdAt",
  //   header: ({ column }) => (
  //     <DataTableColumnHeader
  //       className="text-left "
  //       column={column}
  //       title="Oluşturma Zamanı"
  //     />
  //   ),
  //   cell: (row) => {
  //     return (
  //       <div className="w-full flex  text-xs font-bold pl-3 ">
  //         <TimeAgo date={row.getValue()} formatter={formatter} />
  //       </div>
  //     );
  //   },
  // },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <div className="flex gap-1 items-center justify-start">
        <CalendarClock size={16} />
        <DataTableColumnHeader column={column} title="Güncellenme Zamanı" />
      </div>
    ),
    // Filter: DateRangeColumnFilter,
    // filter: dateBetweenFilterFn,
    cell: (row) => {
      const date = new Date(row.getValue());
      return (
        <div className="w-full flex font-medium pl-2">
          {format(date, "dd.MM.yyyy HH:mm", { locale: tr })}
        </div>
      );
    },
    filterFn: (row, id, value) => {
      const date = new Date(row.getValue(id))
      const today = new Date()
      
      // Bugünün başlangıcı
      const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate())
      
      // Bu haftanın başlangıcı (Pazartesi)
      const startOfWeek = new Date(today)
      startOfWeek.setDate(today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1))
      startOfWeek.setHours(0, 0, 0, 0)
      
      // Bu ayın başlangıcı
      const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1)
      
      // Bu yılın başlangıcı
      const startOfYear = new Date(today.getFullYear(), 0, 1)
      
      // Geçen haftanın başlangıcı ve bitişi
      const startOfLastWeek = new Date(startOfWeek)
      startOfLastWeek.setDate(startOfLastWeek.getDate() - 7)
      const endOfLastWeek = new Date(startOfWeek)
      endOfLastWeek.setMilliseconds(-1)
      
      // Geçen ayın başlangıcı ve bitişi
      const startOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1)
      const endOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
      
      // Geçen yılın başlangıcı ve bitişi
      const startOfLastYear = new Date(today.getFullYear() - 1, 0, 1)
      const endOfLastYear = new Date(today.getFullYear() - 1, 11, 31, 23, 59, 59, 999)
      
      return value.includes("today") && date >= startOfToday ||
             value.includes("week") && date >= startOfWeek ||
             value.includes("month") && date >= startOfMonth ||
             value.includes("year") && date >= startOfYear ||
             value.includes("lastWeek") && date >= startOfLastWeek && date <= endOfLastWeek ||
             value.includes("lastMonth") && date >= startOfLastMonth && date <= endOfLastMonth ||
             value.includes("lastYear") && date >= startOfLastYear && date <= endOfLastYear
    },
  },

  {
    id: "actions",
    cell: ({ row }) => (
      <DataTableRowActions
        row={row}
        actions={actions}
        onPreview={onPreview}
        onPrint={onPrint}
      />
    ),
    size: 20,
    enableSorting: false,
    enableColumnFilter: false,
    enableGlobalFilter: false,
  },
];
