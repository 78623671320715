import api from "@/lib/api.service";

const URL = "/workgroup";

export const getAllWorkingGroups = async () => {
  const { data } = await api.get(`${URL}/list`);

  return data;
};

export const createWorkingGroup = async (savedata) => {
  const { data } = await api.post(`${URL}/new`, savedata);
  return data;
};

export const deleteWorkingGroup = async (id) => {
  const { data } = await api.post(`${URL}/delete`, { id });
  return data;
};

export const updateWorkingGroup = async (updatedata) => {
  const { data } = await api.post(`${URL}/update`, updatedata);
  return data;
};
