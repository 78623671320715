import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { apiconfig } from "@/lib/apiconfig";
import { FilePenLine, QrCode, ScanEye, Trash2 } from "lucide-react";
import { useState } from "react";
import { default as OtherQrCode } from "../QrCode";

const DataTableRowActions = ({
  row,
  onPrintQr,
  onEdit,
  onFillForm,
  onDelete,
}) => {
  const [open, setOpen] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false); // State for alert dialog

  const qrCodeUrl = apiconfig().url + `/fill-form/${row.original.qr_code}`;

  const handleDelete = () => {
    onDelete(row.original._id);
    setAlertOpen(false);
  };

  return (
    <div className="w-full flex gap-2 justify-center items-center">
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onFillForm(row.original)}
            >
              <ScanEye size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu Doldur</p>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => onEdit(row.original)}
            >
              <FilePenLine size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu Düzenle</p>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => setOpen(true)}
            >
              <QrCode size={19} />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Form QR Görüntüle/Yazdır</p>
          </TooltipContent>
        </Tooltip>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="text-muted-foreground "
              size="sm"
              onClick={() => setAlertOpen(true)} // Open the alert dialog
            >
              <Trash2 size={19} color="red" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <p className="text-xs">Formu Sil</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Form Barkodu</DialogTitle>
            <DialogDescription>Forma ait Qr Kodu Önizleme</DialogDescription>
          </DialogHeader>
          <OtherQrCode
            qrCodeUrl={qrCodeUrl}
            qrCode={row.original.qr_code}
            formName={row.original.name}
          />
        </DialogContent>
      </Dialog>

      <AlertDialog open={alertOpen} onOpenChange={setAlertOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Formu Sil</AlertDialogTitle>
            <AlertDialogDescription>
              Bu işlemi gerçekleştirmek istediğinizden emin misiniz? Bu form ile
              ilişkili olan doldurulmuş formlarda silinecektir.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setAlertOpen(false)}>
              Hayır
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Evet</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default DataTableRowActions;
