import DataTable from "@/components/archived-forms-data-table/data-table";
import {
  CheckboxInput,
  SwitchInput,
  TextArea,
  TextInput,
} from "@/components/formfields/Inputs";
import { Button } from "@/components/ui/button";
import { CardContent } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/context/authContext";
import api from "@/lib/api.service";
import { getAllArchivedForms } from "@/lib/archivedForms.service";
import { queryKeys } from "@/lib/queryKey.factory";
import { roles } from "@/lib/roleEnum";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LoaderCircle } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import BreadCrumb from "../../components/BreadCrumb";
import { Heading } from "../../components/Heading";
import { getAllArchivedFormsColumns } from "./archived-forms-columns";

const breadcrumbItems = [
  { title: "Arşivlenen Form Listesi", link: "/dashboard/archived-forms" },
];

export default function ArchivedForms() {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [formDocumentData, setFormDocumentData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  console.log(user);
  if (user.role === roles.DEFAULTUSER || user.role === roles.MANAGER) {
    navigate("/completed-forms");
  }

  const queryClient = useQueryClient();

  const { data: allarhivedForms, isFetching } = useQuery({
    queryKey: queryKeys.fetchArchivedForms.all,
    queryFn: () => getAllArchivedForms(),
    initialData: [],
  });
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
      size: auto;
      margin: 10mm;
    }
    @media print {
      body {
        -webkit-print-color-adjust: exact;
      }
     .print-container {
          overflow: visible !important;
        }
        .scrollable-form {
          overflow: visible !important;
          height: auto !important;
        }
          footer {
          display: none;
        }
         header:{
          display:none;
        }
    }
  `,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  //form detay görüntüleme
  const onPreview = useCallback(async (form) => {
    console.log(form);
    // //navigate(`/inspect-form/${form._id}`);
    setOpen(true);

    let inspectform = await api.get("/archivedform/get?formId=" + form._id);
    const formrawdata = await api.get(
      "/form/get?formId=" + inspectform.data.filled_form_id
    );
    setForm(inspectform.data);
    setFormDocumentData(formrawdata.data.document_data);

    setLoading(false);
  }, []);

  // form print event
  const onPrint = useCallback(async (form) => {
    setLoading(true);
    setOpen(true);
    let inspectform = await api.get("/archivedform/get?formId=" + form._id);
    const formrawdata = await api.get(
      "/form/get?formId=" + inspectform.data.filled_form_id
    );
    setForm(inspectform.data);

    setFormDocumentData(formrawdata.data.document_data);

    setLoading(false);

    handlePrint();
  }, []);

  const getActionByRole = (role) => {
    return [];
  };

  const handleClose = () => {
    setOpen((prev) => !prev);
    setLoading(true);
    setForm({});
    setFormDocumentData({});
  };

  const columns = useMemo(
    () =>
      getAllArchivedFormsColumns({
        actions: getActionByRole(user.role),
        onPreview,
        onPrint,
      }),
    [[user.role]]
  );
  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);

  return (
    <ScrollArea className="h-full">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <BreadCrumb items={breadcrumbItems} />
        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Arşivlenen Form Listesi"}
            description={
              "Yönetici tarafından arşivlenen formların yönetim ekranı"
            }
          />
        </div>
        <Separator />

        <div className="grid grid-cols-1  rounded-lg">
          {isFetching && <span className="px-5 py-5 ">Yükleniyor..</span>}
          {!isFetching && (
            <DataTable data={allarhivedForms} columns={columns} />
          )}
        </div>
      </div>
      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[1224px] ">
          <DialogHeader>
            <DialogTitle>Formu İncele</DialogTitle>
            <DialogDescription>
              Arşivlenen formun detayı gösteriliyor
            </DialogDescription>
          </DialogHeader>
          {loading ? (
            <div className="grid gap-2 ">
              <LoaderCircle className="animate-spin text-muted-foreground" />
              <p className=" text-muted-foreground">form yükleniyor...</p>
            </div>
          ) : (
            <>
              <div className="grid gap-4 py-2">
                <ScrollArea
                  className="max-h-[70vh] h-fit w-full rounded-md border overflow-y-auto   scrollable-form"
                  ref={printRef}
                >
                  <div className="grid grid-cols-1 gap-3">
                    <div>
                      <div className="grid grid-cols-8 border-b">
                        <div className="border-e p-2">
                          <img
                            width="75"
                            className="m-auto"
                            src="https://upload.wikimedia.org/wikipedia/tr/a/a5/Recep_Tayyip_Erdo%C4%9Fan_%C3%9Cniversitesi_logo.jpg"
                          />
                        </div>
                        <div className="grid grid-cols-1 col-span-7 xs:col-span-3 lg:col-span-5 text-center">
                          <div className="text-sm xs:text-md lg:text-lg xl:text-xl border-b font-semibold pt-1">
                            DİŞ HEKİMLİĞİ FAKÜLTESİ
                          </div>
                          <div className="text-sm xs:text-md md:text-xl xl:text-3xl p-2 lg:p-4">
                            {form.name}
                          </div>
                        </div>
                        {formDocumentData && (
                          <div className="grid grid-cols-2 col-span-8 text-xs sm:text-base xs:col-span-4 lg:col-span-2 border-s-0 xs:border-s border-t xs:border-t-0">
                            <div className="border-e p-1 border-b text-slate-700">
                              Döküman Kodu
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_code"]}
                            </div>
                            <div className="border-e p-1 border-b text-slate-700">
                              Revizyon No
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_revision_number"]}
                            </div>
                            <div className="border-e p-1 border-b text-slate-700">
                              Yayın Tarihi
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_release_date"]}
                            </div>
                            <div className="border-e p-1 border-b text-slate-700">
                              Revizyon Tarihi
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_revision_date"]}
                            </div>
                            <div className="border-e p-1 text-slate-700">
                              Sayfa No
                            </div>
                            <div className="p-1 text-slate-700">
                              {formDocumentData["document_page_number"]}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <CardContent className="grid  grid-cols-7 py-2 pb-10  ">
                      <div className="col-span-7 xl:col-span-5 space-y-3 disabled ">
                        {form?.filled_fields?.map((field) => {
                          switch (field.type) {
                            case "form_description_textbox":
                              return (
                                <TextArea
                                  key={field.id}
                                  field={field}
                                  readonly={true}
                                />
                              );
                            case "text":
                            case "number":
                              return (
                                <TextInput
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            case "textbox":
                              return (
                                <TextArea
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            case "switch":
                              return (
                                <SwitchInput
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            case "checkbox":
                              return (
                                <CheckboxInput
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            default:
                              return null;
                          }
                        })}
                      </div>
                    </CardContent>
                    <div className="border-t text-sm">
                      <div className="grid grid-cols-3 border-b bg-slate-50">
                        <div className="border-e p-1 text-center">
                          FORMU DOLDURAN
                        </div>
                        <div className="border-e p-1 text-center">
                          KONTROL EDEN
                        </div>
                        <div className="p-1 text-center">ONAYLAYAN</div>
                      </div>
                      <div className="grid grid-cols-3">
                        <div className="border-e p-1">
                          <div className="p-1">Adı/Soyadı</div>
                          <div className="p-1">
                            {form?.filler_user_is_anon
                              ? "Anonim"
                              : form?.filler_user?.firstname +
                                " " +
                                form?.filler_user?.lastname}
                          </div>
                          <div className="p-1 py-4">İmza</div>
                        </div>
                        <div className="border-e p-1">
                          <div className="p-1">Adı/Soyadı</div>
                          <div className="p-1">
                            {form?.filler_user_is_anon
                              ? "-"
                              : form?.responsible_user?.firstname +
                                " " +
                                form?.responsible_user?.lastname}
                          </div>
                          <div className="p-1 py-4">İmza</div>
                        </div>
                        <div className="p-1">
                          <div className="p-1">Adı/Soyadı</div>
                          <div className="p-1">
                            {form?.archiving_user?.firstname +
                              " " +
                              form?.archiving_user?.lastname}
                          </div>
                          <div className="p-1 py-4">İmza</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollArea>
              </div>
              <DialogFooter>
                <Button type="button" onClick={() => setOpen(false)}>
                  kapat
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </ScrollArea>
  );
}
