import React, { useEffect, useState } from "react";
import {
  TextInput,
  TextArea,
  SwitchInput,
} from "@/components/formfields/Inputs";
import {
  Calculator,
  Calendar as CalendarIcon,
  CircleCheckBig,
  Move,
  SaveAll,
  ScrollText,
  SquareCheckBig,
  Text,
  ToggleLeft,
  Trash2,
} from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { tr } from "date-fns/locale";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { format } from "date-fns";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { apiconfig } from "@/lib/apiconfig";
import { CheckboxInput } from "../components/formfields/Inputs";

const InspectForm = () => {
  const [form, setForm] = useState({});
  const [formDocumentData, setFormDocumentData] = useState({});
  const { formId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let inspectform = await axios.get(
          apiconfig().url + "/api/inspectform/get?formId=" + formId
        );
        const formrawdata = await axios.get(
          apiconfig().url +
            "/api/form/get?formId=" +
            inspectform.data.filled_form_id
        );
        setForm(inspectform.data);
        setFormDocumentData(formrawdata.data.document_data);
      } catch (error) {
        console.error("Veri çekme hatası", error);
        navigate("/completed-forms");
      }
    };

    fetchData();
  }, []);

  return (
    <Card>
      <div className="grid grid-cols-1 gap-3">
        <div>
          <div className="grid grid-cols-8 border-b">
            <div className="border-e p-2">
              <img
                width="75"
                className="m-auto"
                src="https://upload.wikimedia.org/wikipedia/tr/a/a5/Recep_Tayyip_Erdo%C4%9Fan_%C3%9Cniversitesi_logo.jpg"
              />
            </div>
                <div className="grid grid-cols-1 col-span-7 xs:col-span-3 lg:col-span-5 text-center">
                  <div className="text-sm xs:text-md lg:text-lg xl:text-xl border-b font-semibold pt-1">
                    DİŞ HEKİMLİĞİ FAKÜLTESİ
                  </div>
                  <div className="text-sm xs:text-md md:text-xl xl:text-3xl p-2 lg:p-4">
                    {form.name}
                  </div>
                </div>
                {formDocumentData && (
                  <div className="grid grid-cols-2 col-span-8 text-xs sm:text-base xs:col-span-4 lg:col-span-2 border-s-0 xs:border-s border-t xs:border-t-0">
                    <div className="border-e p-1 border-b text-slate-700">
                      Döküman Kodu
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {formDocumentData["document_code"]}
                    </div>
                    <div className="border-e p-1 border-b text-slate-700">
                      Revizyon No
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {formDocumentData["document_revision_number"]}
                    </div>
                    <div className="border-e p-1 border-b text-slate-700">
                      Yayın Tarihi
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {formDocumentData["document_release_date"]}
                    </div>
                    <div className="border-e p-1 border-b text-slate-700">
                      Revizyon Tarihi
                    </div>
                    <div className="p-1 border-b text-slate-700">
                      {formDocumentData["document_revision_date"]}
                    </div>
                    <div className="border-e p-1 text-slate-700">
                      Sayfa No
                    </div>
                    <div className="p-1 text-slate-700">
                      {formDocumentData["document_page_number"]}
                    </div>
                  </div>
                )}
          </div>
        </div>
        <CardContent className="grid grid-cols-7 py-2 pb-10">
          <div></div>
          <div className="col-span-7 xl:col-span-5 space-y-3 disabled">
            {form?.filled_fields?.map((field) => {
              switch (field.type) {
                case "form_description_textbox":
                  return (
                    <TextArea
                      key={field.id}
                      field={field}
                      readonly={true}
                    />
                  );
                case "text":
                case "number":
                  return <TextInput key={field.id} field={field} preview={true} />;
                case "textbox":
                  return <TextArea key={field.id} field={field} preview={true} />;
                case "switch":
                  return <SwitchInput key={field.id} field={field} preview={true} />;
                case "checkbox":
                  return <CheckboxInput key={field.id} field={field} preview={true} />;
                default:
                  return null;
              }
            })}
          </div>
          <div></div>
        </CardContent>
      </div>
    </Card>
  );
};

export default InspectForm;
