/* eslint-disable react/prop-types */ import {
  TableBody,
  Table as TableC,
  TableCell,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Skeleton } from "./ui/skeleton";

const Table = ({
  containerClassName,
  className,
  children,
  tdClassName,
  columns,
  rows: initialData,
  loading,
  rowsPerPageOptionOnHeader = true,
  sort = true,
  draggableRows = false,
  onRowDrop,
  onRowClick,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [rows, setRows] = useState([]);

  useEffect(() => {
    setRows(initialData);
  }, [initialData]);

  useEffect(() => {
    sort &&
      setSortConfig({
        key: columns?.[0]?.column || null,
        direction: "ascending",
      });
  }, [columns, sort]);

  const rowsCount = rows.length;
  const itemsPerPageOptions = {
    10: 10,
    20: 20,
    30: 30,
    50: 50,
    TÜMÜ: rowsCount,
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    setSortConfig({ key: null, direction: "ascending" });
  }, [searchQuery]);

  const currentItems = rows.filter((row) =>
    Object.values(row)
      .join(" ")
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(rowsCount / itemsPerPage);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setSearchQuery("");
  };

  const sortedItems = () => {
    if (sortConfig.key !== null) {
      const sortedRows = [...rows].sort((a, b) => {
        const aValue = a[sortConfig.key];
        const bValue = b[sortConfig.key];
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue, "en", { sensitivity: "base" });
        } else if (typeof aValue === "number" && typeof bValue === "number") {
          return aValue - bValue;
        }
        return 0;
      });

      if (sortConfig.direction === "descending") {
        return sortedRows.reverse();
      }
      return sortedRows;
    }
    return currentItems;
  };

  const onDragStart = (e, rowIndex) => {
    e.dataTransfer.setData("text/plain", rowIndex.toString());
  };

  const onDragOver = (e) => {
    e.preventDefault();
  };

  const onDrop = async (e, targetIndex) => {
    e.preventDefault();

    const sourceIndex = parseInt(e.dataTransfer.getData("text/plain"));
    await moveRow(sourceIndex, targetIndex);

    // indexler eşitse zaten gerek yok işlem yapmaya
  };

  const moveRow = async (sourceIndex, targetIndex) => {
    const updatedRows = [...rows];

    const [draggedRow] = updatedRows.splice(sourceIndex, 1);

    updatedRows.splice(targetIndex, 0, draggedRow);
    const drop = await onRowDrop(
      sourceIndex,
      targetIndex,
      rows[sourceIndex],
      updatedRows
    );
    if (drop) setRows(updatedRows);
  };

  const handleRowClick = (e, row) => {
    if (onRowClick) {
      onRowClick(e, row);
    }
  };

  return (
    <div className={containerClassName}>
      <div className="text-sm rounded-lg text-[--primary-color]">
        <div className="min-w-full overflow-hidden p-2 px-3.5 text-right uppercase">
          <div className="flex">
            {rowsPerPageOptionOnHeader && (
              <div className="flex w-full items-center text-sm space-x-2">
                <label>Sayfa başına</label>
                <Select
                  onValueChange={handleItemsPerPageChange}
                  defaultValue={itemsPerPage}
                >
                  <SelectTrigger className="w-[85px] h-[35px] bg-white">
                    <SelectValue placeholder="Seçiniz" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(itemsPerPageOptions).map(
                      ([label, value]) => (
                        <SelectItem key={value} value={value}>
                          {label}
                        </SelectItem>
                      )
                    )}
                  </SelectContent>
                </Select>
                <label>Kadar kayıt göster</label>
                <label className="text-xs opacity-40">
                  (Toplam {rowsCount} Kayıt)
                </label>
              </div>
            )}
            <div className="w-fit">
              <Input
                value={searchQuery}
                className="float-right w-[250px] h-[35px]"
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Kayıtlarda arayın"
              ></Input>
            </div>
          </div>
        </div>

        <TableC
          parentClassName="border-y bg-white border-slate-200/75"
          className={`min-w-full divide-y divide-gray-200 table-fixed ${className}`}
        >
          <TableHeader>
            <TableRow>
              {columns.map((data, index) => (
                <TableCell
                  key={index}
                  className={`py-3 px-4 text-xs text-left uppercase ${
                    data.visible === false && "sr-only"
                  }`}
                  onClick={sort ? () => handleSort(data.column) : () => {}}
                  style={{
                    cursor: "pointer",
                    width: data.width ? data.width : "unset",
                  }}
                >
                  {data.text}
                  {sort &&
                    sortConfig.key === data.column &&
                    (sortConfig.direction === "ascending" ? (
                      <ChevronDown className="ml-1 inline-block w-4 h-4" />
                    ) : (
                      <ChevronUp className="ml-1 inline-block w-4 h-4" />
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody onDragOver={(e) => onDragOver(e)}>
            {loading ? (
              <TableRow>
                {columns.map((_, i) => {
                  return (
                    <TableCell key={i}>
                      <span className="flex items-center">
                        {/* <Loader size={15} className="mr-2 animate-spin" />
                            Yükleniyor... */}
                        <Skeleton className="h-4 w-full" />
                      </span>
                    </TableCell>
                  );
                })}
              </TableRow>
            ) : (
              rowsCount === 0 && (
                <tr>
                  <td colSpan={columns.length} className="p-4">
                    <span className="flex justify-center items-center">
                      Hiçbir kayıt bulunamadı.
                    </span>
                  </td>
                </tr>
              )
            )}
            {sortedItems()
              .slice(indexOfFirstItem, indexOfLastItem)
              .map((row, rowIndex) => (
                <TableRow
                  key={rowIndex}
                  className={`
                  border-s-0 transition-all duration-75
                  ${
                    row._selected
                      ? "bg-green-100 border-s-4 border-s-blue-400 hover:bg-green-200/80"
                      : rowIndex % 2 === 0
                      ? "bg-slate-100/25"
                      : ""
                  }
                `}
                  draggable={draggableRows}
                  onDragStart={(e) => onDragStart(e, rowIndex)}
                  onDrop={(e) => onDrop(e, rowIndex)}
                  onClick={(e) => handleRowClick(e, row)} // Satır tıklandığında yeni fonksiyonu çağır
                >
                  {columns.map((data, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={
                          "whitespace-nowrap overflow-hidden " +
                          (tdClassName ? tdClassName : "py-3.5 px-4") +
                          " " +
                          data.class
                        }
                      >
                        {data?.render
                          ? data.render(row, row[data.column])
                          : row[data.column]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
          </TableBody>
        </TableC>

        <div className="min-w-full overflow-hidden p-2 flex uppercase">
          <div className="w-full">{children}</div>
          <div className="ms-4">
            <nav
              className="relative bg-white text-[--blue] z-0 inline-flex rounded-md space-x-1"
              aria-label="Pagination"
            >
              <Button
                onClick={() => paginate(currentPage - 1)}
                className="rounded-lg hover:bg-slate-100/60 text-[--blue]"
                disabled={currentPage === 1}
                variant="ghost"
              >
                <ChevronLeft size={20} />
              </Button>
              {Array.from({ length: totalPages }, (_, index) => (
                <Button
                  key={index + 1}
                  variant="ghost"
                  onClick={() => paginate(index + 1)}
                  className={`rounded-lg hover:bg-slate-100/60 ${
                    currentPage === index + 1
                      ? "bg-slate-100/50"
                      : "text-gray-600/75"
                  }`}
                >
                  {index + 1}
                </Button>
              ))}
              <Button
                onClick={() => paginate(currentPage + 1)}
                className="rounded-lg hover:bg-slate-100/60 text-[--blue]"
                disabled={currentPage === totalPages}
                variant="ghost"
              >
                <ChevronRight size={20} />
              </Button>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

const TableFooter = ({ children, className }) => {
  return (
    <div
      className={`min-w-full overflow-hidden text-right uppercase ` + className}
    >
      {children}
    </div>
  );
};

export { Table, TableFooter };
