export const statuses = [
  {
    value: "PENDING",
    label: "Askıda Bekliyor",
    icon: "",
  },
  {
    value: "TOAPPROVAL",
    label: "Onay Bekliyor",
    icon: "",
  },

  {
    value: "NEEDREVISE",
    label: "Revize istiyor",
    icon: "",
  },
  {
    value: "SUCCESS",
    label: "Onaylandı",
    icon: "",
  },
];

export const work_groups = [
  // bu data veritabanından gelecek
  {
    value: "Temizlik birimi",
    label: "Temizlik birimi",
    icon: "",
  },
  {
    value: "Teknik birim",
    label: "Teknik birim",
    icon: "",
  },
];

// Tarih filtreleme seçenekleri
export const dateFilters = [
  {
    value: "today",
    label: "Bugün",
    icon: "",
  },
  {
    value: "week",
    label: "Bu Hafta",
    icon: "",
  },
  {
    value: "month",
    label: "Bu Ay",
    icon: "",
  },
  {
    value: "year",
    label: "Bu Yıl",
    icon: "",
  },
  {
    value: "lastWeek",
    label: "Geçen Hafta",
    icon: "",
  },
  {
    value: "lastMonth",
    label: "Geçen Ay",
    icon: "",
  },
  {
    value: "lastYear",
    label: "Geçen Yıl",
    icon: "",
  },
];
