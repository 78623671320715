import DataTable from "@/components/completed-forms-data-table/data-table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import {
  archiveCompletedForm,
  deleteCompletedForm,
  formHataliRequest,
  formOnaylaRequest,
  formReddetRequest,
  formRevizeOnayRequest,
  formRevizeTalepRequest,
  getAllCompletedForms,
} from "@/lib/completedForms.service";
import { queryKeys } from "@/lib/queryKey.factory";
import { getUser } from "@/lib/user.service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { LoaderCircle } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import { Heading } from "../../components/Heading";
import { getCompletedFormsColumns } from "./completed-forms-columns";

import {
  CheckboxInput,
  SwitchInput,
  TextArea,
  TextInput,
} from "@/components/formfields/Inputs";
import { Logout } from "@/components/Logout";
import { Button } from "@/components/ui/button";
import { CardContent } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/context/authContext";
import api from "@/lib/api.service";
import { roles } from "@/lib/roleEnum";
import { useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";

const breadcrumbItems = [
  { title: "Doldurulan Formlar", link: "/dashboard/completed-froms" },
];

export default function CompletedForms_() {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});
  const [formDocumentData, setFormDocumentData] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [isPrinting, setIsPrinting] = useState(false);
  const printRef = useRef(null);
  // We store the resolve Promise being used in `onBeforeGetContent` here
  const promiseResolveRef = useRef(null);

  const { data: completedForms, isFetching } = useQuery({
    queryKey: queryKeys.fetchCompletedForms.all,
    queryFn: () => getAllCompletedForms(),
    initialData: [],
  });

  const { data: userData, isFetching: isFetchingUser } = useQuery({
    queryKey: queryKeys.fetchUser.single,
    queryFn: () => getUser(),
    initialData: [],
  });

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  //   pageStyle: `
  //     @page {
  //       size: auto;
  //       margin: 0mm;
  //     }
  //     @media print {
  //       body {
  //         -webkit-print-color-adjust: exact;
  //       }
  //       header, footer {
  //         display: none;
  //       }
  //     }
  //   `,
  // });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `
    @page {
          size: auto;
          margin: 3mm;
        }
        @media print {
          body {
            -webkit-print-color-adjust: exact;
          }
          .print-container {
            overflow: visible !important;
          }
          .scrollable-form {
            overflow: visible !important;
            height: auto !important;
          }
          footer, header {
            display: none !important;
          }
          .form-page {
            page-break-after: always;
            border: 1px solid #000;
            margin: 10px;
          }
          .form-page:last-child {
            page-break-after: auto;
          }
          .form-section {
            page-break-inside: avoid;
          }
        }
    `,
    onBeforeGetContent: () => {
      return new Promise((resolve) => {
        promiseResolveRef.current = resolve;
        setIsPrinting(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setIsPrinting(false);
    },
  });

  const formSilMutation = useMutation({
    mutationFn: deleteCompletedForm,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.fetchCompletedForms.all,
      });
    },
  });

  const formArsivleMutation = useMutation({
    mutationFn: ({ _id, archiving_user_id }) =>
      archiveCompletedForm(_id, archiving_user_id),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.fetchCompletedForms.all,
      });
    },
  });

  const formReddetMutation = useMutation({
    mutationFn: (id) => formReddetRequest(id),
    onSuccess: async (data) => {
      console.log("formReddet request data:", data);
      if (data.status) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.fetchCompletedForms.all,
        });
        toast({
          variant: "outline",
          title: "Başarılı",
          description: "Form reddedildi",
          status: "success",
        });
      }
    },
  });
  const formOnaylaMutation = useMutation({
    mutationFn: (id) => formOnaylaRequest(id),
    onSuccess: async (data) => {
      console.log("formOnayla request data:", data);
      if (data.status) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.fetchCompletedForms.all,
        });
        toast({
          variant: "outline",
          title: "Başarılı",
          description: data.message,
          status: "success",
        });
      }
    },
  });

  const formHataliMutation = useMutation({
    mutationFn: (id) => formHataliRequest(id),
    onSuccess: async (data) => {
      console.log("formHatali request data:", data);
      if (data.status) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.fetchCompletedForms.all,
        });
        toast({
          title: "Başarılı",
          description: data.message,
          status: "success",
        });
      }
    },
  });

  const formRevizeTalepMutation = useMutation({
    mutationFn: (id) => formRevizeTalepRequest(id),
    onSuccess: async (data) => {
      console.log("form Revize Talep Request request data:", data);
      if (data.status) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.fetchCompletedForms.all,
        });
        toast({
          variant: "outline",
          title: "Başarılı",
          description: data.message,
          status: "success",
        });
      }
    },
  });
  const formRevizeOnayMutation = useMutation({
    mutationFn: (id) => formRevizeOnayRequest(id),
    onSuccess: async (data) => {
      console.log("form Revize Talep Request request data:", data);
      if (data.status) {
        await queryClient.invalidateQueries({
          queryKey: queryKeys.fetchCompletedForms.all,
        });
        toast({
          variant: "outline",
          title: "Başarılı",
          description: data.message,
          status: "success",
        });
      }
    },
  });

  //form detay görüntüleme
  const onPreview = useCallback(async (form) => {
    console.log(form);
    //navigate(`/inspect-form/${form._id}`);
    setOpen(true);

    let inspectform = await api.get("/inspectform/get?formId=" + form._id);
    const formrawdata = await api.get(
      "/form/get?formId=" + inspectform.data.filled_form_id
    );
    setForm(inspectform.data);
    setFormDocumentData(formrawdata.data.document_data);

    setLoading(false);
  }, []);

  // form print event
  const onPrint = useCallback(async (form) => {
    setLoading(true);
    setOpen(true);
    let inspectform = await api.get("/inspectform/get?formId=" + form._id);
    const formrawdata = await api.get(
      "/form/get?formId=" + inspectform.data.filled_form_id
    );
    setForm(inspectform.data);

    setFormDocumentData(formrawdata.data.document_data);

    setLoading(false);

    handlePrint();
  }, []);

  const getActionByRole = (role) => {
    switch (role) {
      case roles.DEFAULTUSER:
        return [
          {
            label: "Revize Talep",
            action: (row) => revizeTalep(row),
          },
          {
            label: "Düzenle",
            action: (form) => navigate(`/edit-filled-form/${form._id}`),
          },
        ];
      case roles.MANAGER:
        return [
          { label: "Form Reddet", action: (row) => formReddet(row) },
          { label: "Formu Onayla", action: (row) => formOnayla(row) },
          {
            label: "Formu Sil",
            action: (row) => formSilMutation.mutate(row._id),
          },
        ];

      case roles.ADMIN:
        return [
          // {
          //   label: "Form İncele",
          //   action: (row) => formIncele(row),
          // },
          // {
          //   label: "Form Yazdır",
          //   action: (row) => formYazdir(row),
          // },
          {
            label: "Hatalı Form",
            action: (row) => formHatali(row),
          },
          {
            label: "Revizeye Gönder",
            action: (row) => revizeGonder(row),
          },
          {
            label: "Formu Arşivle",
            action: (row) =>
              formArsivleMutation.mutate({
                _id: row._id,
                archiving_user_id: user._id,
              }),
          },
          {
            label: "Formu Sil",
            action: (row) => formSilMutation.mutate(row._id),
          },
        ];
      default:
        return [];
    }
  };

  // const formIncele = (row) => {
  //   console.log("formincele");
  // };
  // const formYazdir = (row) => {
  //   console.log("form yazdır");
  // };

  const columns = useMemo(
    () =>
      getCompletedFormsColumns({
        actions: getActionByRole(user.role),
        onPreview,
        onPrint,
      }),
    [user.role]
  );

  const handleClose = () => {
    setOpen((prev) => !prev);
    setLoading(true);
    setForm({});
    setFormDocumentData({});
  };

  const formHatali = useCallback((form) => {
    formHataliMutation.mutate(form._id);
  }, []);

  const revizeGonder = useCallback((form) => {
    if (form.status === "INREVISE") {
      toast({
        title: "Revize isteği",
        description: "Formun daha önce revize isteği onaylanmış.",
        status: "warning",
      });
      return;
    }
    formRevizeOnayMutation.mutate(form._id);
  }, []);
  const formOnayla = useCallback((form) => {
    formOnaylaMutation.mutate(form._id);
  }, []);

  const formReddet = useCallback((form) => {
    formReddetMutation.mutate(form._id);
  }, []);

  const revizeTalep = useCallback((form) => {
    formRevizeTalepMutation.mutate(form._id);
  }, []);

  let filteredForms = [];

  if (!isFetching) {
    if (user.role === roles.MANAGER) {
      filteredForms = completedForms.filter(
        (form) =>
          form.responsible_user && form.responsible_user._id === user._id
      );
    } else if (user.role === roles.DEFAULTUSER) {
      filteredForms = completedForms.filter(
        (form) => form.filler_user && form.filler_user_id === user._id
      );
    } else if (user.role === roles.ADMIN) {
      filteredForms = completedForms; // Tüm formlar
    }
  }

  // We watch for the state to change here, and for the Promise resolve to be available
  useEffect(() => {
    if (isPrinting && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [isPrinting]);
  console.log(printRef.current);

  return (
    <ScrollArea className="h-full">
      <div className="flex-1 space-y-4 p-4 pt-6 md:p-8">
        <div className="flex w-full items-center justify-between ">
          <BreadCrumb items={breadcrumbItems} />
          <Logout />
        </div>

        <div className="flex items-center justify-between space-y-2">
          <Heading
            title={"Doldurulan Formlar"}
            description={
              "Personel tarafından doldurulan ve işlenmeyi bekleyein formların yönetim ekranı"
            }
          />
        </div>
        <Separator />

        <div className="grid grid-cols-1  rounded-lg">
          {isFetching && <span className="px-5 py-5 ">Yükleniyor..</span>}
          {!isFetching && (
            <DataTable data={filteredForms} columns={columns} user={user} />
          )}
        </div>
      </div>

      <Dialog open={open} onOpenChange={handleClose}>
        <DialogContent className="sm:max-w-[1224px] ">
          <DialogHeader>
            <DialogTitle>Formu İncele</DialogTitle>
            <DialogDescription>
              Doldurulan formun detayı gösteriliyor
            </DialogDescription>
          </DialogHeader>
          {loading ? (
            <div className="grid gap-2 ">
              <LoaderCircle className="animate-spin text-muted-foreground" />
              <p className=" text-muted-foreground">form yükleniyor...</p>
            </div>
          ) : (
            <>
              <div className="grid gap-4 py-2">
                <ScrollArea
                  className="max-h-[70vh] h-fit w-full rounded-md border overflow-y-auto scrollable-form  "
                  ref={printRef}
                >
                  <div className="grid grid-cols-1 gap-3  ">
                    <div>
                      <div className="grid grid-cols-8 border-b">
                        <div className="border-e p-2">
                          <img
                            width="75"
                            className="m-auto"
                            src="https://upload.wikimedia.org/wikipedia/tr/a/a5/Recep_Tayyip_Erdo%C4%9Fan_%C3%9Cniversitesi_logo.jpg"
                          />
                        </div>
                        <div className="grid grid-cols-1 col-span-7 xs:col-span-3 lg:col-span-5 text-center">
                          <div className="text-sm xs:text-md lg:text-lg xl:text-xl border-b font-semibold pt-1">
                            DİŞ HEKİMLİĞİ FAKÜLTESİ
                          </div>
                          <div className="text-sm xs:text-md md:text-xl xl:text-3xl p-2 lg:p-4">
                            {form.name}
                          </div>
                        </div>
                        {formDocumentData && (
                          <div className="grid grid-cols-2 col-span-8 text-xs sm:text-base xs:col-span-4 lg:col-span-2 border-s-0 xs:border-s border-t xs:border-t-0">
                            <div className="border-e p-1 border-b text-slate-700">
                              Döküman Kodu
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_code"]}
                            </div>
                            <div className="border-e p-1 border-b text-slate-700">
                              Revizyon No
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_revision_number"]}
                            </div>
                            <div className="border-e p-1 border-b text-slate-700">
                              Yayın Tarihi
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_release_date"]}
                            </div>
                            <div className="border-e p-1 border-b text-slate-700">
                              Revizyon Tarihi
                            </div>
                            <div className="p-1 border-b text-slate-700">
                              {formDocumentData["document_revision_date"]}
                            </div>
                            <div className="border-e p-1 text-slate-700">
                              Sayfa No
                            </div>
                            <div className="p-1 text-slate-700">
                              {formDocumentData["document_page_number"]}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <CardContent className="grid  grid-cols-7 py-2 pb-10  ">
                      <div className="col-span-7 xl:col-span-5 space-y-3 disabled ">
                        {form?.filled_fields?.map((field) => {
                          switch (field.type) {
                            case "form_description_textbox":
                              return (
                                <TextArea
                                  key={field.id}
                                  field={field}
                                  readonly={true}
                                />
                              );
                            case "text":
                            case "number":
                              return (
                                <TextInput
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            case "textbox":
                              return (
                                <TextArea
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            case "switch":
                              return (
                                <SwitchInput
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            case "checkbox":
                              return (
                                <CheckboxInput
                                  key={field.id}
                                  field={field}
                                  preview={true}
                                />
                              );
                            default:
                              return null;
                          }
                        })}
                      </div>
                    </CardContent>
                  </div>
                </ScrollArea>
              </div>
              <DialogFooter>
                <Button type="button" onClick={() => setOpen(false)}>
                  kapat
                </Button>
              </DialogFooter>
            </>
          )}
        </DialogContent>
      </Dialog>
    </ScrollArea>
  );
}
