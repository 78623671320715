import { Button } from "@/components/ui/button";

import {
  ArrowDownIcon,
  ArrowUpIcon,
  ChevronsLeftRightIcon,
} from "lucide-react";

const DataTableColumnHeader = ({ column, title, className }) => {
  const renderSortIcon = () => {
    const sort = column.getIsSorted();
    if (!sort) {
      return <ChevronsLeftRightIcon className="ml-2 h-4 w-4 rotate-90" />;
    }
    return sort === "desc" ? (
      <ArrowDownIcon className="ml-2 h-4 w-4" />
    ) : (
      <ArrowUpIcon className="ml-2 h-4 w-4" />
    );
  };

  if (!column.getCanSort()) {
    return <div className={className}>{title}</div>;
  }

  return (
    <div className={className}>
      <Button
        variant="ghost"
        size="sm"
        className="h-8"
        onClick={column.getToggleSortingHandler()}
      >
        <span className={""}>{title}</span>
        {renderSortIcon()}
      </Button>
    </div>
  );
};

export default DataTableColumnHeader;
